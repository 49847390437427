import React, { useState } from 'react'
import { Button, Checkbox, Col, DatePicker, Form, PageHeader, Row } from 'antd';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import './ConceptMovementsFilters.css';
import { DEFAULT_DATE_API_FORMAT, DEFAULT_DATE_FORMAT } from '../../../constants';

export interface IConceptMovementFilter {
  [x: string]: string | null | boolean;
  dateFrom: string | null;
  dateTo: string | null;
  includeZeroValues: boolean;
}

interface ConceptMovementsFiltersProps {
  onChange: (filter: IConceptMovementFilter) => void;
}

const ConceptMovementsFilters = ({
  onChange
} : ConceptMovementsFiltersProps) => {
  const { t } = useTranslation();
  const [formNumber, setFormNumber] = useState<number>(1);
  const [filter, setFilter] = useState<IConceptMovementFilter>({
    dateFrom: null,
    dateTo: null,
    includeZeroValues: false
  });

  const handleFilterChange = (key: string, value: string | boolean | null) => {
    setFilter((prevFilter) => {
      return ({
        ...prevFilter,
        [key]: value
      });
    });
  };

  const handleDateFromChange = (value: Moment | null) => {
    if (value) return handleFilterChange('dateFrom', value.format(DEFAULT_DATE_API_FORMAT));
    handleFilterChange('dateFrom', value)
  };

  const handleDateToChange = (value: Moment | null) => {
    if (value) return handleFilterChange('dateTo', value.format(DEFAULT_DATE_API_FORMAT));
    handleFilterChange('dateTo', value)
  };

  const handleIncludeZeroValuesChange = (value: boolean) => {
    handleFilterChange('includeZeroValues', value);
  };

  const handleFilterReset = () => {
    setFilter({
      dateFrom: null,
      dateTo: null,
      includeZeroValues: false
    });
    setFormNumber((prev) => prev + 1);
  };

  return (
    <PageHeader className="pageHeader" >
      <Form
        className="filtersForm"
        layout="inline"
        initialValues={filter}
        key={`filter_form_${formNumber}`}
      >
        <Row className='mainRow'>
          <Col className='mainColumn'>
            <Row gutter={[12, 12]}>
              <Col span={8}>
                <Form.Item name="dateFromFilter" label={t('modules.movements.filters.dateFrom')}>
                  <DatePicker
                    format={DEFAULT_DATE_FORMAT}
                    onChange={handleDateFromChange}
                    placeholder={t('common.selectDate')}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="dateToFilter" label={t('modules.movements.filters.dateTo')}>
                  <DatePicker
                    format={DEFAULT_DATE_FORMAT}
                    onChange={handleDateToChange}
                    placeholder={t('common.selectDate')}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="includeZeroValuesFilter">
                  <Checkbox
                    checked={filter.includeZeroValues}
                    onChange={(e) => handleIncludeZeroValuesChange(e.target.checked)}
                  >
                    {t('modules.concepts.filters.zeroValues')}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className='mainColumn filterButtons'>
            <Button onClick={handleFilterReset}>{t('common.filters.reset')}</Button>
            <Button onClick={() => onChange(filter)} type='primary'>{t('common.filters.apply')}</Button>
          </Col>
        </Row>
      </Form>
    </PageHeader>
  );
};

export default ConceptMovementsFilters;