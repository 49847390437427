import IEndpoint, { HttpMethods } from '../../interfaces/IEndpoint';

const CreateCashDateEndpoint: IEndpoint = {
    method: HttpMethods.PUT,
    url: 'treasuries',
    composeUrl: (treasuryId: string) => `treasuries/${treasuryId}/cashdate`,
    formatError: (errorResponse) => errorResponse.response.data,
};

export default CreateCashDateEndpoint;
