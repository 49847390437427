export enum Permission {
  ADMIN = 'ADMIN',
  ACCOUNTANCY = 'ACCOUNTANCY',
  ACCOUNTANCY_SUP = 'ACCOUNTANCY_SUP',
}

interface DescriptableEntity {
  id: string;
  description: string;
}

export interface CustomPermission {
  permissions: DescriptableEntity[];
  roles: DescriptableEntity[];
}