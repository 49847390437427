import IEndpoint, { HttpMethods } from '../../interfaces/IEndpoint';

const ListTreasuryMovementsEndpoint: IEndpoint = {
    method: HttpMethods.GET,
    url: 'treasuries/list',
    composeUrl: (treasuryId: string) => `treasuries/${treasuryId}/movements`,
    formatError: (errorResponse) => errorResponse.response.data,
};

export default ListTreasuryMovementsEndpoint;
