import React from 'react';
import FormContainer from '../../../containers/FormContainer';
import { communityFormSchema } from '../schema';

export interface CommunityBody {
  id?: string;
  communityId: string;
  name: string;
}

interface CommunityFormProps {
  onSubmit: (body: CommunityBody) => void;
  entity?: CommunityBody;
}

const CommunityForm = ({ onSubmit, entity }: CommunityFormProps) => (
  <FormContainer<CommunityBody>
    schema={communityFormSchema}
    onSubmit={onSubmit}
    {...(entity ? { data: entity } : {})}
  />
);

export default CommunityForm;
