import IEndpoint, { HttpMethods } from '../../interfaces/IEndpoint';

const CreateTreasuryEndpoint: IEndpoint = {
    method: HttpMethods.POST,
    includeCommunityId: true,
    url: 'treasuries',
    formatError: (errorResponse) => errorResponse.response.data,
};

export default CreateTreasuryEndpoint;
