import IEndpoint, { HttpMethods } from '../../interfaces/IEndpoint';

const CreateMovementEndpoint: IEndpoint = {
    method: HttpMethods.POST,
	includeCommunityId: true,
    url: 'movements',
    formatError: (errorResponse) => errorResponse.response.data,
};

export default CreateMovementEndpoint;
