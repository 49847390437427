import IEndpoint, { HttpMethods } from '../../interfaces/IEndpoint';

const CreateTransferEndpoint: IEndpoint = {
    method: HttpMethods.POST,
    url: 'treasuries/transfer',
    formatError: (errorResponse) => errorResponse.response.data,
    includeCommunityId: true,
};

export default CreateTransferEndpoint;
