import React from 'react';
import FormContainer from '../../../containers/FormContainer';
import useApi from '../../../hooks/useAPI';
import { CreateCashDate } from '../../../http/endpoints/treasuries';
import { treasuryCashDateFormSchema } from '../schema';

export interface CashDateBody {
  cashDate: Date,
  initialBalance: number;
  currentBalance: number;
}

const CashDateModal = ({
  treasuryId,
  onSuccess,
  onError,
  currentBalance,
}: {
  treasuryId: string,
  onSuccess: () => void,
  onError: (error: string) => void,
  currentBalance: number
}) => {
  
  const { call, loading } = useApi({
    endpoint: CreateCashDate,
    onSuccess,
    onError
  });

  const handleSubmit = (data: CashDateBody) => {
    call({id: treasuryId, data});
  };

  return (
    <FormContainer
      onSubmit={handleSubmit}
      processing={loading}
      schema={{
        ...treasuryCashDateFormSchema,
        initialValues: {
          initialBalance: currentBalance,
        }
      }}      
    />
  );
};

export default CashDateModal;