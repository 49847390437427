import { IModuleRoutes, IRoute } from '../../components/Routes/RouteWrapper';
import { Permission } from '../../types/permissions';
import UserDetail from './views/UserDetail';
import UserList from './views/UserList';

export const routePrefix = '/users';

const routes : Array<IRoute> = [{
  exact: true,
  title: 'modules.users.list',
  navLabel: 'modules.users.list',
  key: 'user_list',
  path: `${routePrefix}/list`,
  component: UserList,
  requiredPermissions: [Permission.ADMIN]
}, {
  exact: true,
  title: 'modules.treasuries.detail',
  key: 'user_detail',
  path: `${routePrefix}/detail/:Id`,
  component: UserDetail,
  requiredPermissions: [Permission.ADMIN]
}];

const moduleRoutes : IModuleRoutes = {
  key: 'users_module',
  title: 'modules.users.plural',
  requiresCommunity: true,
  routes,
};

export default moduleRoutes;