import create from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';

import { User } from '../types/user';
import { StateKey, StateLoading, StateRefresh, StateSet } from './keys';
import { Concept } from '../types/concept';
import { Treasury } from '../types/treasury';
import { Movement } from '../types/movement';
import { CustomPermission } from '../types/permissions';
import { Community } from '../types/community';

type Store = {
  // auth
  [StateKey.USER]: User | null;
  [StateSet.SET_CLEAN_SESSION]: () => any;
  [StateSet.SET_USER]: (user: User) => any;

  // table
  [StateKey.EXPORT_DATATABLE]: boolean;
  [StateSet.SET_EXPORT_DATATABLE]: (exportDataTable: boolean) => any;
  [StateLoading.EXPORTING_DATATABLE]: boolean;
  [StateSet.SET_EXPORTING_DATATABLE]: (exportingDataTable: boolean) => any;

  // currentCommunity
  [StateKey.CURRENT_COMMUNITY]: string | null;
  [StateSet.SET_CURRENT_COMMUNITY]: (communityId: string) => any;

  // authorization
  [StateKey.PERMISSIONS]: CustomPermission | null;
  [StateLoading.LOADING_PERMISSIONS]: boolean;
  [StateSet.SET_PERMISSIONS]: (permissions: CustomPermission) => any;
  [StateSet.SET_LOADING_PERMISSIONS]: (loading: boolean) => any;
  [StateRefresh.REFRESH_PERMISSIONS]: boolean;
  [StateSet.SET_REFRESH_PERMISSIONS]: (loading: boolean) => any;

  // concepts
  [StateKey.CONCEPTS]: Array<Concept>;
  [StateLoading.LOADING_CONCEPTS]: boolean;
  [StateSet.SET_CONCEPTS]: (concepts: Array<Concept>) => any;
  [StateSet.SET_LOADING_CONCEPTS]: (loading: boolean) => any;
  [StateRefresh.REFRESH_CONCEPTS_LIST]: boolean;
  [StateSet.SET_REFRESH_CONCEPTS_LIST]: (loading: boolean) => any;

  // treasuries
  [StateKey.TREASURIES]: Array<Treasury>;
  [StateLoading.LOADING_TREASURIES]: boolean;
  [StateSet.SET_TREASURIES]: (treasuries: Array<Treasury>) => any;
  [StateSet.SET_LOADING_TREASURIES]: (loading: boolean) => any;
  [StateRefresh.REFRESH_TREASURIES_LIST]: boolean;
  [StateSet.SET_REFRESH_TREASURIES_LIST]: (loading: boolean) => any;
  [StateRefresh.REFRESH_TREASURY_DETAIL]: boolean;
  [StateSet.SET_REFRESH_TREASURY_DETAIL]: (loading: boolean) => any;

  // movements
  [StateKey.MOVEMENTS]: Array<Movement>;
  [StateLoading.LOADING_MOVEMENTS]: boolean;
  [StateSet.SET_MOVEMENTS]: (movements: Array<Movement>) => any;
  [StateSet.SET_LOADING_MOVEMENTS]: (loading: boolean) => any;
  [StateRefresh.REFRESH_MOVEMENTS_LIST]: boolean;
  [StateSet.SET_REFRESH_MOVEMENTS_LIST]: (loading: boolean) => any;

  // users
  [StateKey.USERS]: Array<User>;
  [StateLoading.LOADING_USERS]: boolean;
  [StateSet.SET_USERS]: (users: Array<User>) => any;
  [StateSet.SET_LOADING_USERS]: (loading: boolean) => any;
  [StateRefresh.REFRESH_USERS_LIST]: boolean;
  [StateSet.SET_REFRESH_USERS_LIST]: (loading: boolean) => any;
  [StateRefresh.REFRESH_USER_DETAIL]: boolean;
  [StateSet.SET_REFRESH_USER_DETAIL]: (loading: boolean) => any;

  // community
  [StateKey.COMMUNITIES]: Array<Community>;
  [StateLoading.LOADING_COMMUNITIES]: boolean;
  [StateSet.SET_COMMUNITIES]: (users: Array<Community>) => any;
  [StateSet.SET_LOADING_COMMUNITIES]: (loading: boolean) => any;
  [StateRefresh.REFRESH_COMMUNITIES_LIST]: boolean;
  [StateSet.SET_REFRESH_COMMUNITIES_LIST]: (loading: boolean) => any;
};

const useStore = create<Store>((set) => {
  return {
    user: null,
    setCleanSession: () =>
      set((state) => ({
        ...state,
        concepts: [],
        treasuries: [],
        communities: [],
        movements: [],
        user: null,
        currentCommunity: null,
      })),
    setUser: (user: User) =>
      set((state) => ({
        ...state,
        user,
      })),
    refreshUserDetail: false,
    setRefreshUserDetail: (refresh: boolean) =>
      set((state) => ({
        ...state,
        refreshUserDetail: refresh,
      })),
    currentCommunity: null,
    setCurrentCommunity: (currentCommunity: string) =>
      set((state) => ({
        ...state,
        currentCommunity,
      })),
    // AUTHORIZATION
    permissions: null,
    loadingPermissions: false,
    setPermissions: (permissions: CustomPermission) =>
      set((state) => ({
        ...state,
        permissions,
      })),
    setLoadingPermissions: (loading: boolean) =>
      set((state) => ({
        ...state,
        loadingPermissions: loading,
      })),
    refreshPermissions: false,
    setRefreshPermissions: (refresh: boolean) =>
      set((state) => ({
        ...state,
        refreshPermissions: refresh,
      })),
    // TABLE
    exportDataTable: false,
    exportingDataTable: false,
    setExportDataTable: (exportDataTable: boolean) =>
      set((state) => ({
        ...state,
        exportDataTable,
      })),
    setExportingDataTable: (exportingDataTable: boolean) =>
      set((state) => ({
        ...state,
        exportingDataTable,
      })),
    // CONCEPTS
    concepts: [],
    loadingConcepts: false,
    setConcepts: (concepts: Array<Concept>) =>
      set((state) => ({
        ...state,
        concepts,
      })),
    setLoadingConcepts: (loading: boolean) =>
      set((state) => ({
        ...state,
        loadingConcepts: loading,
      })),
    refreshConceptsList: false,
    setRefreshConceptsList: (refresh: boolean) =>
      set((state) => ({
        ...state,
        refreshConcepts: refresh,
      })),
    // TREASURIES
    treasuries: [],
    loadingTreasuries: false,
    setTreasuries: (treasuries: Array<Treasury>) =>
      set((state) => ({
        ...state,
        treasuries,
      })),
    setLoadingTreasuries: (loading: boolean) =>
      set((state) => ({
        ...state,
        loadingTreasuries: loading,
      })),
    refreshTreasuriesList: false,
    setRefreshTreasuriesList: (refresh: boolean) =>
      set((state) => ({
        ...state,
        refreshTreasuriesList: refresh,
      })),
    refreshTreasuryDetail: false,
    setRefreshTreasuryDetail: (refresh: boolean) =>
      set((state) => ({
        ...state,
        refreshTreasuryDetail: refresh,
      })),
    // MOVEMENTS
    movements: [],
    loadingMovements: false,
    setMovements: (movements: Array<Movement>) =>
      set((state) => ({
        ...state,
        movements,
      })),
    setLoadingMovements: (loading: boolean) =>
      set((state) => ({
        ...state,
        loadingMovements: loading,
      })),
    refreshMovementsList: false,
    setRefreshMovementsList: (refresh: boolean) =>
      set((state) => ({
        ...state,
        refreshMovementsList: refresh,
      })),
    // USERS
    users: [],
    loadingUsers: false,
    setUsers: (users: Array<User>) =>
      set((state) => ({
        ...state,
        users,
      })),
    setLoadingUsers: (loading: boolean) =>
      set((state) => ({
        ...state,
        loadingUsers: loading,
      })),
    refreshUsersList: false,
    setRefreshUsersList: (refresh: boolean) =>
      set((state) => ({
        ...state,
        refreshUsersList: refresh,
      })),
    // COMMUNITY
    communities: [],
    loadingCommunities: false,
    setCommunities: (communities: Array<Community>) =>
      set((state) => ({
        ...state,
        communities,
      })),
    setLoadingCommunities: (loading: boolean) =>
      set((state) => ({
        ...state,
        loadingCommunities: loading,
      })),
    refreshCommunitiesList: false,
    setRefreshCommunitiesList: (refresh: boolean) =>
      set((state) => ({
        ...state,
        refreshCommunitiesList: refresh,
      })),
  };
});

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('mainStore', useStore as any);
}

export default useStore;
