import React from 'react';
import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import FormContainer from '../../../containers/FormContainer';
import useApi from '../../../hooks/useAPI';
import { CreateTransfer } from '../../../http/endpoints/treasuries';
import { formatMoney, padDecimals } from '../../../utils/formatter';
import { treasuryTransferFormSchema } from '../schema';

export interface TransferBody {
  cashDate: Date,
}

const TransferModal = ({
  treasuryId,
  onSuccess,
  onError,
  currentBalance,
}: {
  treasuryId: string,
  onSuccess: () => void,
  onError: (error: string) => void,
  currentBalance: number
}) => {
  const { t } = useTranslation();
  const { call, loading } = useApi({
    endpoint: CreateTransfer,
    onSuccess,
    onError
  });

  const handleSubmit = (data: TransferBody) => {
    call({id: treasuryId, data});
  };

  return (
    <>
      <Descriptions>
      <Descriptions.Item label={t('modules.treasuries.schema.availableBalance')} span={2}>
          {formatMoney(padDecimals(currentBalance))}
        </Descriptions.Item>
      </Descriptions>
      <FormContainer
        processing={loading}
        onSubmit={handleSubmit} 
        schema={{
          ...treasuryTransferFormSchema,
          initialValues: {
            sourceTreasury: treasuryId,
            currentBalance,
          },
        }}      
      />
    </>
  );
};

export default TransferModal;