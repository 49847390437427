import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Modal, PageHeader, Space } from 'antd';
import IEndpoint from '../../http/interfaces/IEndpoint';
import { StateRefresh, StateSet } from '../../state/keys';
import { Entity } from '../../types/entity';
import { ITableColumnSchema } from '../../types/tableSchema';
import DataTableContainer from '../DataTableContainer';
import './ListContainer.css';
import { SummaryItem } from '../../components/DataTable/DataTable';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import useModal from '../../hooks/useModal';
import useStore from '../../state/store';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const ACTION_KEY = 'action';

interface ListContainerProps<T, TBody> {
  entityKey: string;
  headerTitle: string;
  headerSubtitle?: string;

  formComponent: any;
  handleCreate?: (body: TBody) => void;
  handleUpdate?: (body: TBody, rawData?: TBody) => void;
  handleDelete?: (body: TBody) => void;
  allowCreate?: boolean;
  allowDetail?: string;
  allowEdit?: boolean;
  allowDelete?: boolean;
  allowSelect?: boolean;
  allowExport?: boolean;
  selectAndSet?: (entity: string) => void;
  shouldAllowSelect?: (entity: T) => boolean;

  withoutPagination?: boolean;
  
  filters?: {};
  filtersComponent?: JSX.Element | null;
  
  getEndpoint: IEndpoint;
  setRefreshStateKey: StateSet;
  refreshListKey: StateRefresh;
  columns: Array<ITableColumnSchema>;
  displaySummary?: boolean;
  summarySchema?: Array<SummaryItem>;
  pageSize?: number;
};

const ListContainer = <
  T extends Entity,
  TBody extends {
    id?: string;
  },
>({
  entityKey,
  headerTitle,
  headerSubtitle = '',
  formComponent: FormComponent,
  handleCreate,
  handleUpdate,
  handleDelete,
  getEndpoint,
  setRefreshStateKey,
  refreshListKey,
  columns,
  allowDetail = '',
  allowCreate = true,
  allowEdit = true,
  allowExport = false,
  allowSelect = false,
  allowDelete = false,
  filters = {},
  filtersComponent = null,
  selectAndSet,
  shouldAllowSelect,
  displaySummary,
  summarySchema,
  pageSize,
  withoutPagination
}: ListContainerProps<T, TBody>) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedEntity, setSelectedEntity] = useState<TBody | null>(null);
  const {
    id,
    visible,
    openModal,
    closeModal
  } = useModal({
    onDismiss: () => setSelectedEntity(null)
  });
  const { setExportDataTable } = useStore();

  useEffect(() => {
    if (selectedEntity) openModal();
  }, [selectedEntity]);

  const redirectToDetail = (entity: { id: string }) => {
    history.push(`${allowDetail}/detail/${entity.id}`);
  };

  const openConfirmationModal = (entity: TBody) => {
    Modal.confirm({
      title: t('common.deleteConfirmation'),
      icon: <ExclamationCircleOutlined />,
      content: t('common.irreversible'),
      onOk: () => handleDelete?.(entity),
    });
  }

  const getComputedColumns = () => {
    if ([allowDetail, allowEdit, allowDelete, selectAndSet].every((flag) => !flag)) return columns;
    const columnsWithActions: Array<ITableColumnSchema> = [...columns];
    const actions: ITableColumnSchema = {
      title: t('common.actions'),
      key: ACTION_KEY,
      render: (_, record) => {
        return (
          <Space>
            {
              allowDetail && (
                <Button type="link" onClick={() => redirectToDetail(record)}>
                  {t('common.openDetail')}
                </Button>
              )
            }
            {
              allowEdit && (
                <Button type="link" onClick={() => setSelectedEntity(record)}>
                  {t('common.update')}
                </Button>
              )
            }
            {
              allowDelete && (
                <Button type="link" onClick={() => openConfirmationModal(record)}>
                  {t('common.delete')}
                </Button>
              )
            }
            {
              selectAndSet && (shouldAllowSelect?.(record) || allowSelect) && (
                <Button type="link" onClick={() => selectAndSet(record.id)}>
                  {t('common.select')}
                </Button>
              )
            }
          </Space>
        )
      },
    };

    columnsWithActions.push(actions);

    return columnsWithActions;
  };

  const handleSubmit = (data: TBody, rawData?: TBody) => {
    if (data.id && handleUpdate) handleUpdate?.(data, rawData);
    else if (handleCreate) handleCreate?.(data);
    closeModal();
  };

  return (
    <>
      <Modal
        key={id}
        title={`${selectedEntity ? t('common.update') : t('common.create')} ${t(entityKey)}`}
        visible={visible}
        onCancel={closeModal}
        footer={null}
      >
        <FormComponent onSubmit={handleSubmit} entity={selectedEntity} />
      </Modal>
      <PageHeader
        onBack={() => history.goBack()}
        ghost={false}
        title={t(headerTitle)}
        subTitle={t(headerSubtitle)}
        className="list-header"
        extra={[
          allowCreate ? (
            <Button key="list_create_button" type="primary" onClick={openModal}>
              {t('common.create')}
            </Button>
          ) : null,
          allowExport ? (
            <Button key="list_create_button" type="primary" onClick={() => setExportDataTable(true)}>
              {t('common.export')}
            </Button>
          ) : null,
        ]}
      />
      { filtersComponent }
      <DataTableContainer<T>
        endpoint={getEndpoint}
        setRefreshStateKey={setRefreshStateKey}
        refreshListKey={refreshListKey}
        columns={getComputedColumns()}
        filters={filters}
        displaySummary={displaySummary}
        summarySchema={summarySchema}
        {...(
          withoutPagination 
            ? { withoutPagination: true } 
            : { pageSize: pageSize || DEFAULT_PAGE_SIZE}
        )}
      />
    </>
  );
};

export default ListContainer;
