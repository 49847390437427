import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SessionService from '../../services/Session';
import useStore from '../../state/store';

const ForceLogout = () => {
  const history = useHistory();
  const { setCleanSession } = useStore();

  useEffect(() => {
    SessionService.destroyCurrentSession(() => {
      setCleanSession();
      history.push('/login');
    });
  }, []);
  
  return (null);
};

export default ForceLogout;