/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useRef, useState } from 'react';

import { ApiClient } from '../http/ApiClient';
import IApiClient from '../http/interfaces/IApiClient';
import IRequestNonMandatoryParams from '../http/interfaces/IRequestNonMandatoryParams';
import IEndpoint from '../http/interfaces/IEndpoint';
import AuthenticationService from '../services/Authentication';

const BASE_API_URL = `${process.env.REACT_APP_BACKEND_API_URL}`;

interface IApiHook {
  endpoint: IEndpoint;
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}

const useApi = ({ endpoint, onSuccess, onError }: IApiHook) => {
  // TODO: CREATE GLOBAL TOAST HOOK
  // TODO: INTEGRATE I18N HOOK
  const client = useRef<IApiClient>(ApiClient.getInstance());
  const [loading, setLoading] = useState<boolean>(false);

  const handleSuccess = useCallback((responseData) => {
    if (onSuccess) {
      onSuccess(
        endpoint.formatResponse
          ? endpoint.formatResponse(responseData)
          : responseData
      );
    }
  }, []);

  const handleError = useCallback((responseError) => {
    if (onError) {
      onError(
        endpoint.formatError
          ? endpoint.formatError(responseError)
          : responseError
      );
    }
  }, []);

  const get = useCallback(
    (request?: IRequestNonMandatoryParams) => {
      let url = BASE_API_URL;
      
      if (endpoint.composeUrl && request?.id) url = `${url}${endpoint.composeUrl(request.id)}`;
      else if (request?.id && !endpoint.composeUrl) url = `${url}${endpoint.url}/${request.id}`;
      else url = `${url}${endpoint.url}`

      if (
        client.current &&
        !client.current.isAlreadyFetching(url)
      ) {
        setLoading(true);
        client.current
          .get(url, request)
          .then((responseData) => {
            handleSuccess(responseData);
          })
          .catch((e) => {
            handleError(e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [client]
  );

  const post = useCallback(
    (request?: IRequestNonMandatoryParams) => {
      if (
        client.current &&
        !client.current.isAlreadyFetching(`${BASE_API_URL}${endpoint.url}`) &&
        request
      ) {
        setLoading(true);
        if (request.data && endpoint.includeCommunityId) {
          (request.data as { communityId: string }).communityId = AuthenticationService.getInstance().getCommunityId();
        }
        client.current
          .post(`${BASE_API_URL}${endpoint.url}`, request)
          .then((responseData) => {
            handleSuccess(responseData);
          })
          .catch((e) => {
            handleError(e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [client]
  );

  const put = useCallback(
    (request?: IRequestNonMandatoryParams) => {
      let url = BASE_API_URL;
      
      if (endpoint.composeUrl && request?.id) url = `${url}${endpoint.composeUrl(request.id)}`;
      else if (request?.id && !endpoint.composeUrl) url = `${url}${endpoint.url}/${request.id}`;
      else url = `${url}${endpoint.url}`

      if (
        client.current &&
        !client.current.isAlreadyFetching(`${BASE_API_URL}${url}`) &&
        request?.id
      ) {
        setLoading(true);
        client.current
          .put(url, request)
          .then((responseData) => {
            handleSuccess(responseData);
          })
          .catch((e) => {
            handleError(e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [client]
  );

  const remove = useCallback(
    (request?: IRequestNonMandatoryParams) => {
      let url = BASE_API_URL;
      
      if (endpoint.composeUrl && request?.id) url = `${url}${endpoint.composeUrl(request.id)}`;
      else if (request?.id && !endpoint.composeUrl) url = `${url}${endpoint.url}/${request.id}`;
      else url = `${url}${endpoint.url}`

      if (
        client.current &&
        !client.current.isAlreadyFetching(url)
      ) {
        setLoading(true);
        client.current
          .delete(url, request!)
          .then((responseData) => {
            handleSuccess(responseData);
          })
          .catch((e) => {
            handleError(e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [client]
  );

  const methodMapper = {
    get,
    post,
    put,
    'delete': remove,
  };

  return {
    call: methodMapper[endpoint.method],
    loading,
  };
};

export default useApi;
