import { IModuleRoutes, IRoute } from '../../components/Routes/RouteWrapper';
import { Permission } from '../../types/permissions';
import MovementList from './views/MovementList';
import MovementReport from './views/MovementReport';

const routePrefix = '/movements';

const routes : Array<IRoute> = [{
  exact: true,
  title: 'modules.movements.list',
  navLabel: 'modules.movements.list',
  key: 'movement_list',
  path: `${routePrefix}/list`,
  component: MovementList,
  requiredPermissions: [Permission.ADMIN, Permission.ACCOUNTANCY]
}, {
  exact: true,
  title: 'modules.movements.report',
  navLabel: 'modules.movements.report',
  key: 'movement_report',
  path: `${routePrefix}/report`,
  component: MovementReport,
  requiredPermissions: [Permission.ADMIN, Permission.ACCOUNTANCY]
}];

const moduleRoutes : IModuleRoutes = {
  key: 'movements_module',
  title: 'modules.movements.plural',
  requiresCommunity: true,
  routes,
};

export default moduleRoutes;