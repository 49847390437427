import IEndpoint, { HttpMethods } from '../../interfaces/IEndpoint';

const DeleteMovementEndpoint: IEndpoint = {
    method: HttpMethods.DELETE,
    url: 'movements',
    composeUrl: (movementId: string) => `movements/${movementId}`,
    formatError: (errorResponse) => errorResponse.response.data,
};

export default DeleteMovementEndpoint;
