import { AES } from 'crypto-js';
import SessionService from './Session';

class AuthenticationService {
  private static instance: AuthenticationService;

  private constructor() {}

  static getInstance(): AuthenticationService {
    if (!AuthenticationService.instance) {
      AuthenticationService.instance = new AuthenticationService();
    }
    return AuthenticationService.instance;
  }

  static encryptPassword(password: string): string {
    return AES.encrypt(
      password,
      process.env.REACT_APP_SECRET_LOGIN_KEY || ''
    ).toString();
  }

  getToken = () => SessionService.getCurrentSession().access_token;
  getCommunityId = () => SessionService.getCurrentSession().community_id;
  isAuthenticated = () => !!this.getToken();
}

export default AuthenticationService;
