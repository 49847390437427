export enum StateRefresh {
  REFRESH_CONCEPTS_LIST = 'refreshConceptsList',
  REFRESH_TREASURIES_LIST = 'refreshTreasuriesList',
  REFRESH_MOVEMENTS_LIST = 'refreshMovementsList',
  REFRESH_USERS_LIST = 'refreshUsersList',
  REFRESH_PERMISSIONS = 'refreshPermissions',
  REFRESH_COMMUNITIES_LIST = 'refreshCommunitiesList',
  REFRESH_USER_DETAIL = 'refreshUserDetail',
  REFRESH_TREASURY_DETAIL = 'refreshTreasuryDetail',
}

export enum StateLoading {
  LOADING_CONCEPTS = 'loadingConcepts',
  LOADING_COMMUNITIES = 'loadingCommunities',
  LOADING_TREASURIES = 'loadingTreasuries',
  LOADING_MOVEMENTS = 'loadingMovements',
  LOADING_USERS = 'loadingUsers',
  LOADING_PERMISSIONS = 'loadingPermissions',
  EXPORTING_DATATABLE = 'exportingDataTable',
}

export enum StateSet {
  SET_USER = 'setUser',
  SET_CURRENT_COMMUNITY = 'setCurrentCommunity',
  SET_CLEAN_SESSION = 'setCleanSession',
  SET_CONCEPTS = 'setConcepts',
  SET_LOADING_CONCEPTS = 'setLoadingConcepts',
  SET_REFRESH_CONCEPTS_LIST = 'setRefreshConceptsList',
  SET_TREASURIES = 'setTreasuries',
  SET_LOADING_TREASURIES = 'setLoadingTreasuries',
  SET_REFRESH_TREASURIES_LIST = 'setRefreshTreasuriesList',
  SET_MOVEMENTS = 'setMovements',
  SET_LOADING_MOVEMENTS = 'setLoadingMovements',
  SET_REFRESH_MOVEMENTS_LIST = 'setRefreshMovementsList',
  SET_USERS = 'setUsers',
  SET_LOADING_USERS = 'setLoadingUsers',
  SET_REFRESH_USERS_LIST = 'setRefreshUsersList',
  SET_REFRESH_TREASURY_DETAIL = 'setRefreshTreasuryDetail',
  SET_PERMISSIONS = 'setPermissions',
  SET_LOADING_PERMISSIONS = 'setLoadingPermissions',
  SET_COMMUNITIES = 'setCommunities',
  SET_LOADING_COMMUNITIES = 'setLoadingCommunities',
  SET_REFRESH_COMMUNITIES_LIST = 'setRefreshCommunitiesList',
  SET_REFRESH_PERMISSIONS = 'setRefreshPermissions',
  SET_REFRESH_USER_DETAIL = 'setRefreshUserDetail',
  SET_EXPORT_DATATABLE = 'setExportDataTable',
  SET_EXPORTING_DATATABLE = 'setExportingDataTable',
}

export enum StateKey {
  PERMISSIONS = 'permissions',
  CONCEPTS = 'concepts',
  TREASURIES = 'treasuries',
  COMMUNITIES = 'communities',
  MOVEMENTS = 'movements',
  CURRENT_COMMUNITY = 'currentCommunity',
  USER = 'user',
  USERS = 'users',
  EXPORT_DATATABLE = 'exportDataTable',
}
