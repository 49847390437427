import { Checkbox, DatePicker, DatePickerProps } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IField } from '../../types/schema';
import moment, { Moment } from 'moment';
import useStore from '../../state/store';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const DateInput = (props: DatePickerProps & IField['extra']) => {
  const { t } = useTranslation();
  const [disableDateRestrictions, setDisableDateRestrictions] = useState(false);
  const { user } = useStore();

  useEffect(() => {
    if (!disableDateRestrictions) {
      const defaultDate = moment();
      const { onChange } = props;
      onChange?.(defaultDate, defaultDate.format('DD-MM-YYYY'));
    }
  }, [disableDateRestrictions]);

  const requiredPermissions = useMemo(() => (
    props.avoidDateRestrictions?.some((permission) => user?.permissions[permission])
  ), [props.avoidDateRestrictions]);

  const restrictDates = useMemo(() => (!disableDateRestrictions), [user, disableDateRestrictions]);

  const disabledDate = useCallback((candidate: Moment) => {
    if (!props.dateRestrictions) return false;
    const { from, to } = props.dateRestrictions.between!;
    return !moment(candidate).isBetween(from, to, 'D', '[]');
  }, [props.dateRestrictions]);

  const handleCheckbox = (e: CheckboxChangeEvent) => {
    setDisableDateRestrictions(!e.target.checked);
  }

  return (
    <div>
      <DatePicker
        {...props}
        {...(restrictDates && props.dateRestrictions ? { disabledDate } : {})}
      />
      <br />
      <Checkbox
          style={{ marginTop: 8, marginBottom: 8 }}
          checked={!disableDateRestrictions}
          disabled={!requiredPermissions}
          onChange={handleCheckbox}
        >
          {t('modules.movements.form.fields.dateRestrictionCheckbox')}
        </Checkbox>
    </div>
  )
};

export default DateInput;