import { Input } from 'antd';
import { DEFAULT_DATE_FORMAT } from '../../constants';
import { FieldType } from '../../types/schema';
import NumericInput from '../../components/NumericInput';
import DateInput from '../../components/DateInput';

export const getFieldTypeComponent = (type: FieldType) => {
  let defaultConfig = {};
  let component = null;

  switch (type) {
    case FieldType.DATE:
      defaultConfig = {
        format: DEFAULT_DATE_FORMAT
      };
      component = DateInput;
      break;
    case FieldType.NUMBER:
      defaultConfig = {
        decimalSeparator: ',',
        min: 0,
      };
      component = NumericInput;
      break;
    default:
      component = Input;
      defaultConfig = {
        autocomplete: 'off'
      };
      break;
  }
  return {
    component,
    defaultConfig,
  }
}