import moment, { Moment } from 'moment';
import { IField } from '../types/schema';


export const getFieldByName = (fields: Array<IField>, name: string) : IField | null=> {
  const field = fields.find((f) => f.name === name) || null;
  return field;
};

export const initializeDate = (date: Date | string | Moment) : Moment => {
  let copy = date;
  if (typeof copy === 'string') {
    const [day, month, year] = (date as string).split('/');    
    copy = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  };
  return moment(copy);
};