import moment from 'moment';
import { StateKey } from '../../state/keys';
import { FieldType, IFormSchema } from '../../types/schema';
import { Permission } from '../../types/permissions';

export enum MovementKeys {
  FORM_NAME = 'movement_form',
  DESCRIPTION = 'description',
  TREASURY_ID = 'treasuryId',
  MOVEMENT_TYPE = 'movementType',
  VOUCHER = 'voucher',
  MOVEMENT_DATE = 'movementDate',
  CONCEPT_ID = 'conceptId',
  AMOUNT = 'amount'
}

export const movementFormSchema : IFormSchema = {
  name: MovementKeys.FORM_NAME,
  initialValues: {
    remember: false,
    [MovementKeys.MOVEMENT_TYPE]: 'O',
    [MovementKeys.MOVEMENT_DATE]: moment(),
  },
  submit: {
    label: 'common.confirm',
    className: 'movement-form-button'
  },
  fields: [{
    name: MovementKeys.TREASURY_ID,
    label: 'modules.movements.form.fields.treasury',
    type: FieldType.SELECT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
    extra: {
      optionsFrom: StateKey.TREASURIES,
      allowSearch: true,
    }
  }, {
    name: MovementKeys.MOVEMENT_TYPE,
    label: 'modules.movements.form.fields.movementType',
    type: FieldType.SELECT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
    extra: {
      staticOptions: [{
        id: 'I',
        description: 'Entrada'
      }, {
        id: 'O',
        description: 'Salida'
      }]
    }
  }, {
    name: MovementKeys.VOUCHER,
    label: 'modules.movements.form.fields.voucher',
    type: FieldType.TEXT,
    rules: [{
      required: false,
      message: 'common.requiredField',
    }],
  }, {
    name: MovementKeys.MOVEMENT_DATE,
    label: 'modules.movements.form.fields.movementDate',
    type: FieldType.DATE,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
    placeholder: 'common.selectDate',
    extra: {
      avoidDateRestrictions: [Permission.ADMIN, Permission.ACCOUNTANCY_SUP],
      dateRestrictions: {
        between: {
          from: moment().startOf('M'),
          to: moment().endOf('M'),
        }
      }
    }
  }, {
    name: MovementKeys.DESCRIPTION,
    label: 'modules.movements.form.fields.description',
    type: FieldType.TEXT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
  }, {
    name: MovementKeys.CONCEPT_ID,
    label: 'modules.movements.form.fields.concept',
    type: FieldType.SELECT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
    extra: {
      optionsFrom: StateKey.CONCEPTS,
      allowSearch: true,
    }
  }, {
    name: MovementKeys.AMOUNT,
    label: 'modules.movements.form.fields.amount',
    type: FieldType.NUMBER,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
  }]
};

export const getSchemaWithUpdatedField = ({
  treasuryId,
  defaultTreasury,
}: {
  treasuryId?: string,
  defaultTreasury?: string,
  accountancySupervisor?: boolean
}) => {
  console.log({ treasuryId, defaultTreasury });
  
  const schemaClone = {...movementFormSchema};
  if (treasuryId || defaultTreasury) {
    schemaClone.initialValues.treasuryId = treasuryId || defaultTreasury;
    const field = schemaClone.fields.find((field) => field.name === MovementKeys.TREASURY_ID);
    if (treasuryId && field) field.disabled = true;
  }
  return schemaClone;
};