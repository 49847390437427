import IEndpoint, { HttpMethods } from '../../interfaces/IEndpoint';

const TotalsByConceptEndpoint: IEndpoint = {
    method: HttpMethods.GET,
    url: 'concepts/totals-by-concept',
    includeCommunityId: true,
    formatError: (errorResponse) => errorResponse.response.data,
};

export default TotalsByConceptEndpoint;
