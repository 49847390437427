import { Moment } from 'moment';
import FormContainer from '../../../containers/FormContainer';
import { initializeDate } from '../../../utils/fieldHelper';
import { getSchemaWithUpdatedField } from '../schema';
import useStore from '../../../state/store';
import { useMemo } from 'react';

export interface MovementBody {
  id?: string;
  description: string;
  createdAt: Moment;
  concept: string;
  treasury: string;
  movementType: string;
  movementDate: Moment;
  amount: number;
  voucher: string;
  movementType_unformatted?: string;
}

interface MovementFormProps {
  onSubmit: (body: MovementBody) => void;
  entity?: MovementBody;
  treasuryId?: string;
}

const MovementForm = ({ onSubmit, entity, treasuryId }: MovementFormProps) => {
  const treasuries = useStore((s) => s.treasuries);
  const defaultTreasury = useMemo(() => (
    treasuries.find((treasury) => treasury.default)
  ), [treasuries]);

  return (
    <FormContainer<MovementBody>
      onSubmit={onSubmit}
      schema={getSchemaWithUpdatedField({ treasuryId: treasuryId || entity?.treasury, defaultTreasury: defaultTreasury?.id })}
      {...(entity ? { data: {
        ...entity,
        movementDate: initializeDate(entity.movementDate),
      } } : {})}
    />
  );
};

export default MovementForm;
