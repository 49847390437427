import { FieldType, IFormSchema } from '../../types/schema';

enum CommunityKeys {
  FORM_NAME = 'community_form',
  NAME = 'name',
}

export const communityFormSchema : IFormSchema = {
  name: CommunityKeys.FORM_NAME,
  initialValues: {
    remember: false,
  },
  submit: {
    label: 'common.confirm',
    className: 'community-form-button'
  },
  fields: [{
    name: CommunityKeys.NAME,
    label: 'modules.communities.form.fields.name',
    type: FieldType.TEXT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
  }]
};