import { Input, InputNumberProps } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const numberRegex = new RegExp(/[0-9,]/);

const NumericInput = (props: InputNumberProps<number>) => {
  const [finalValue, setFinalValue] = useState(props.value?.toString());
  const { t } = useTranslation();

  const handleInput = (e: React.FormEvent) => {
    const { value } = (e.target as unknown as { value: string });
    if (numberRegex.test(value)) {
      const [integer, decimal] = value.split('.');
      if (decimal?.length > 2) {
        setFinalValue(`${integer}.${decimal.substring(0, 2)}`);
        return;
      }
    }
    setFinalValue(value);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseFloat(e.target.value);
    props.onChange?.(isNaN(parsedValue) ? 0 : parseFloat(parsedValue.toFixed(2)));
  };

  return (
    <div>
      <Input
        {...props}
        value={finalValue}
        onChange={handleChange}
        onInput={handleInput}
        placeholder={t('modules.movements.form.fields.amount_placeholder')}
        type='number'
        min={0}
        step={0.01}
      />
      <span><i>* {t('common.currency.numberDisclaimer')}</i></span>
    </div>
  );
};

export default NumericInput;