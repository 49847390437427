import { FieldType, IFormSchema } from '../../types/schema';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

enum LoginKeys {
  FORM_NAME = 'login_form',
  USERNAME = 'username',
  PASSWORD = 'password',
}

export const loginSchema: IFormSchema = {
  name: LoginKeys.FORM_NAME,
  initialValues: {
    remember: true,
  },
  submit: {
    label: 'login.form.submit',
    className: 'loginFormButton',
  },
  fields: [
    {
      name: LoginKeys.USERNAME,
      rules: [
        {
          required: true,
          message: 'login.form.username.validation',
        },
      ],
      type: FieldType.TEXT,
      placeholder: 'login.form.username.placeholder',
      extra: {
        autocomplete: true,
        prefix: <UserOutlined className="login-form-icon" />,
      },
    },
    {
      name: LoginKeys.PASSWORD,
      rules: [
        {
          required: true,
          message: 'login.form.password.validation',
        },
      ],
      type: FieldType.TEXT,
      placeholder: 'login.form.password.placeholder',
      extra: {
        prefix: <LockOutlined className="login-form-icon" />,
        type: 'password',
      },
    },
  ],
};
