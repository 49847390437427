import React, { useEffect, useState } from 'react';
import ListContainer from '../../../containers/ListContainer/ListContainer';
import useApi from '../../../hooks/useAPI';
import { List } from '../../../http/endpoints/movements';
import { GetAll as GetAllConcepts } from '../../../http/endpoints/concepts';
import { GetAll as GetAllTreasuries } from '../../../http/endpoints/treasuries';
import { StateRefresh, StateSet } from '../../../state/keys';
import useStore from '../../../state/store';
import { getFormattedSchema, Movement } from '../../../types/movement';
import MovementForm, { MovementBody } from './MovementForm';
import MovementFilters, { IMovementFilter } from '../partials/MovementFilters';

const MovementReport = () => {
  const {
    setConcepts,
    setTreasuries
  } = useStore((state) => state);
  const [filters, setFilters] = useState<IMovementFilter>({
    concepts: [],
    dateFrom: null,
    dateTo: null,
    movementType: 'X',
  });
  
  const { call: getAllConcepts } = useApi({
    endpoint: GetAllConcepts,
    onSuccess: (data) => { setConcepts(data) },
  });

  const { call: getAllTreasuries } = useApi({
    endpoint: GetAllTreasuries,
    onSuccess: (data) => { setTreasuries(data) },
  });

  useEffect(() => {
    getAllConcepts();
    getAllTreasuries();
  }, []);
  
  return (
    <ListContainer<Movement, MovementBody>
      entityKey="modules.movements.single"
      headerTitle="modules.movements.plural"
      formComponent={MovementForm}
      getEndpoint={List}
      setRefreshStateKey={StateSet.SET_REFRESH_MOVEMENTS_LIST}
      refreshListKey={StateRefresh.REFRESH_MOVEMENTS_LIST}
      columns={getFormattedSchema()}
      filters={filters}
      filtersComponent={<MovementFilters onChange={setFilters} />}
      allowCreate={false}
      allowEdit={false}
      allowExport
      withoutPagination
    />
  );
};

export default MovementReport;
