import React from 'react';
import ListContainer from '../../../containers/ListContainer/ListContainer';
import useApi from '../../../hooks/useAPI';
import { Create, List, Update } from '../../../http/endpoints/users';
import { StateRefresh, StateSet } from '../../../state/keys';
import useStore from '../../../state/store';
import { routePrefix } from '../routes';
import { User, UserSchema } from '../../../types/user';
import UserForm, { UserBody } from './UserForm';
import AuthenticationService from '../../../services/Authentication';

const UserList = () => {
  const { setRefreshUsersList } = useStore((state) => state);

  const { call: callCreate } = useApi({
    endpoint: Create,
    onSuccess: () => setRefreshUsersList(true),
  });

  const { call: callUpdate } = useApi({
    endpoint: Update,
    onSuccess: () => setRefreshUsersList(true),
  });

  const handleCreate = (data: UserBody) => {
    callCreate({
      data: {
        ...data,
        password: AuthenticationService.encryptPassword(data.password),
      }
    });
  };
  const handleUpdate = (data: UserBody) => callUpdate({ id: data.id, data });

  return (
    <ListContainer<User, UserBody>
      entityKey="modules.users.single"
      headerTitle="modules.users.plural"
      allowEdit={false}
      formComponent={UserForm}
      handleCreate={handleCreate}
      handleUpdate={handleUpdate}
      getEndpoint={List}
      allowDetail={routePrefix}
      setRefreshStateKey={StateSet.SET_REFRESH_USERS_LIST}
      refreshListKey={StateRefresh.REFRESH_USERS_LIST}
      columns={UserSchema}
    />
  );
};

export default UserList;
