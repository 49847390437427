import React from 'react';
import { Card } from 'antd';

import './LoginForm.css';
import FormContainer from '../../containers/FormContainer';
import { loginSchema } from './schema';

export type LoginBody = {
  username: string;
  password: string;
};

interface LoginFormProps {
  onSubmit: (x: LoginBody) => void;
}

const LoginForm = ({ onSubmit }: LoginFormProps) => (
  <Card className="LoginForm">
    <FormContainer<LoginBody> onSubmit={onSubmit} schema={loginSchema} />
  </Card>
);

export default LoginForm;
