import { ITableColumnSchema } from './tableSchema';

export type Community = {
  id: string;
  name: string;
  createdAt: Date;
};

export const CommunitySchema: Array<ITableColumnSchema> = [
  {
    title: 'modules.communities.schema.name',
    dataIndex: 'name',
    key: 'name',
  },
];
