import React from 'react';
import FormContainer from '../../../containers/FormContainer';
import useApi from '../../../hooks/useAPI';
import { UpdatePassword } from '../../../http/endpoints/users';
import AuthenticationService from '../../../services/Authentication';
import { updatePasswordSchema } from '../schema';

export interface UpdatePasswordBody {
  password: string,
  passwordConfirmation: string,
}

const UpdatePasswordModal = ({
  userId,
  onSuccess,
  onError,
}: {
  userId: string,
  onSuccess: () => void,
  onError: (error: string) => void,
}) => {
  const { call, loading } = useApi({
    endpoint: UpdatePassword,
    onSuccess,
    onError
  });

  const handleSubmit = (data: UpdatePasswordBody) => {
    call({
      id: userId,
      data: {
        password: AuthenticationService.encryptPassword(data.password),
        passwordConfirmation: AuthenticationService.encryptPassword(data.passwordConfirmation),
      },
    });
  };

  return (
    <FormContainer
      processing={loading}
      onSubmit={handleSubmit} 
      schema={updatePasswordSchema}      
    />
  );
};

export default UpdatePasswordModal;