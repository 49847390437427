import React from 'react';
import { Button, Form, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { FieldType, IField, IFormSchema } from '../../types/schema';
import { getFieldTypeComponent } from './fieldMapper';
import './FormContainer.css';
import useStore from '../../state/store';

interface FormContainerProps<TBody> {
  onSubmit: (body: TBody, rawData?: TBody) => void;
  schema: IFormSchema;
  data?: TBody & { id?: string };
  processing?: boolean;
}

const FormContainer = <T extends {}>({
  onSubmit,
  schema,
  data,
  processing,
}: FormContainerProps<T>) => {
  const { t } = useTranslation();
  const store = useStore();

  const getComputedInitialValues = () => {
    const initialValues = { ...schema.initialValues, ...(data || {}) };
    return initialValues;
  };

  const handleFieldMap = (field: IField) => {
    let options: any = null;
    let cleanProps = {};
    const {
      defaultConfig,
      component: FieldComponent
    } = getFieldTypeComponent(field.type);

    const rules = field.rules.map((rule) => ({
      ...rule,
      message: t(rule.message),
    }));
    
    if (field.extra) {
      if (field.extra.optionsFrom) options = store[field?.extra?.optionsFrom];
      if (field.extra.excludeOption && options) {
        options = (options as { description: string }[]).filter((item) => (
          item.description !== field.extra?.excludeOption
        ));
      }
      if (field.extra.avoidDuplicatedValueFrom && options) {
        const valueToAvoid = schema.initialValues[field.extra.avoidDuplicatedValueFrom];
        options = (options as { id: string }[]).filter((item) => item.id !== valueToAvoid);
      };
      if (field.extra.staticOptions) options = field.extra.staticOptions;
      if (field.extra.validateMaxFrom) {
        field.extra.max = schema.initialValues[field.extra.validateMaxFrom];
      }

      const {
        optionsFrom,
        avoidDuplicatedValueFrom,
        validateMaxFrom,
        staticOptions,
        allowSearch,
        ...extra
      } = field.extra;

      cleanProps = extra;
    }

    const renderSelect = () => (
      <Select
        {...cleanProps}
        {...(field.extra?.allowSearch ? {
          showSearch: true,
          filterOption: (input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        } : {})}
        {...(field.disabled ? { disabled: true } : {})}
      >
        {
          (options as [{
            id: string,
            description: string,
            code?: string,
          }]).map((option) => {
            const optPrefix = option.code;
            let translatedDescription = option.description;
            if (field?.extra?.shouldTranslate) {
              translatedDescription = t(`${field?.extra?.shouldTranslate}.${option.description}`)
            }
            const optLabel = optPrefix 
              ? `${optPrefix} - ${translatedDescription}`: translatedDescription;
            const optValue = option.id;
            
            return (
              <Select.Option key={`option_${optValue}`} value={optValue}>{optLabel}</Select.Option>
            );
          })
        }
      </Select>
    );

    const renderDefaultField = (style?: React.CSSProperties) => (
      <FieldComponent
        {...(style ? { style } : {})}
        {...defaultConfig}
        {...(field.placeholder
          ? { placeholder: t(field.placeholder) }
          : {})}
        {...cleanProps}
        {...(field.disabled ? { disabled: true } : {})}
      />
    );

    const renderComputedField = () => {
      if (field.type === FieldType.SELECT && options) return renderSelect();
      return renderDefaultField();
    }

    return (
      <Form.Item
        name={field.name}
        key={field.name}
        rules={rules}
        {...(field.label ? { label: t(field.label) } : {})}
      >
        {renderComputedField()}
      </Form.Item>
    );
  };

  return (
    <Form
      name={schema.name}
      initialValues={getComputedInitialValues()}
      onFinish={(values) => onSubmit(values, data)}
      {...(data ? { key: data.id } : {})}
    >
      {data?.id && <Form.Item name={'id'} hidden />}
      {schema.fields.map(handleFieldMap)}
      <Form.Item className="buttonContainer">
        <Button
          type="primary"
          htmlType="submit"
          disabled={processing}
          className={`form-submit-button ${schema.submit.className}`}
        >
          { processing ? <Spin /> : t(schema.submit.label) }
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormContainer;
