import moment, { Moment } from 'moment';
import { StateKey } from '../../state/keys';
import { FieldType, IFormSchema } from '../../types/schema';
import { Permission } from '../../types/permissions';

enum TreasuryKeys {
  FORM_NAME = 'treasury_form',
  CASH_DATE_FORM_NAME = 'cash_date_form',
  TRANSFER_FORM_NAME = 'transfer_form',
  DESCRIPTION = 'description',
  CASH_DATE = 'cashDate',
  INITIAL_BALANCE = 'initialBalance',
  SOURCE_TREASURY = 'sourceTreasury',
  TARGET_TREASURY = 'targetTreasury',
  AMOUNT = 'amount',
}

export const treasuryFormSchema : IFormSchema = {
  name: TreasuryKeys.FORM_NAME,
  initialValues: {
    remember: false,
    initialBalance: 0,
    cashDate: moment()
  },
  submit: {
    label: 'common.confirm',
    className: 'treasury-form-button'
  },
  fields: [{
    name: TreasuryKeys.DESCRIPTION,
    label: 'modules.treasuries.form.fields.description',
    type: FieldType.TEXT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
  }, {
    name: TreasuryKeys.CASH_DATE,
    label: 'modules.treasuries.form.fields.cashDate',
    type: FieldType.DATE,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
    extra: {
      disabledDate: (current: Moment) => {
        return current && current > moment().endOf('day');
      }
    }
  }, {
    name: TreasuryKeys.INITIAL_BALANCE,
    label: 'modules.treasuries.form.fields.initialBalance',
    type: FieldType.NUMBER,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }]
  }]
}

export const treasuryCashDateFormSchema : IFormSchema = {
  name: TreasuryKeys.CASH_DATE_FORM_NAME,
  initialValues: {
    remember: false,
    cashDate: moment(),
  },
  submit: {
    label: 'common.confirm',
    className: 'treasury-cash-date-form-button'
  },
  fields: [{
    name: TreasuryKeys.CASH_DATE,
    label: 'modules.treasuries.form.fields.cashDate',
    type: FieldType.DATE,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
    extra: {
      avoidDateRestrictions: [Permission.ADMIN, Permission.ACCOUNTANCY_SUP],
      dateRestrictions: {
        between: {
          from: moment().startOf('M'),
          to: moment().endOf('M'),
        }
      }
    }
  }]
}

export const treasuryTransferFormSchema : IFormSchema = {
  name: TreasuryKeys.CASH_DATE_FORM_NAME,
  initialValues: {
    remember: false,
  },
  submit: {
    label: 'common.confirm',
    className: 'treasury-cash-date-form-button'
  },
  fields: [{
    name: TreasuryKeys.SOURCE_TREASURY,
    label: 'modules.treasuries.form.fields.sourceTreasury',
    type: FieldType.SELECT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
    extra: {
      optionsFrom: StateKey.TREASURIES,
    }
  }, {
    name: TreasuryKeys.TARGET_TREASURY,
    label: 'modules.treasuries.form.fields.targetTreasury',
    type: FieldType.SELECT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
    extra: {
      optionsFrom: StateKey.TREASURIES,
      avoidDuplicatedValueFrom: 'sourceTreasury'
    }
  }, {
    name: TreasuryKeys.AMOUNT,
    label: 'modules.treasuries.form.fields.amount',
    type: FieldType.NUMBER,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
    extra: {
      validateMaxFrom: 'currentBalance'
    }
  }]
}