import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import AuthenticationService from '../services/Authentication';

import IApiClient from './interfaces/IApiClient';
import IRequestNonMandatoryParams from './interfaces/IRequestNonMandatoryParams';
import IResponse from './interfaces/IResponse';

export class ApiClient implements IApiClient {
  axiosInstance: AxiosInstance;

  callRegistry: Set<string>;

  static instance: ApiClient;

  private constructor() {
    this.callRegistry = new Set<string>();
    this.axiosInstance = axios.create();
    this.success = this.success.bind(this);
    this.error = this.error.bind(this);
    this.axiosInstance.interceptors.request.use(this.beforeRequest);
    this.axiosInstance.interceptors.response.use(this.success, this.error);
  }

  private async beforeRequest(config: AxiosRequestConfig) {
    const token = AuthenticationService.getInstance().getToken();
    const communityId = AuthenticationService.getInstance().getCommunityId();
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
      ...(communityId ? { 'x-community-id': communityId } : {}),
    };

    return config;
  }

  static getInstance(): ApiClient {
    if (!ApiClient.instance) ApiClient.instance = new ApiClient();
    return ApiClient.instance;
  }

  public isAlreadyFetching(url: string): boolean {
    return this.callRegistry.has(url);
  }

  public get(
    url: string,
    extra: IRequestNonMandatoryParams = {}
  ): Promise<IResponse> {
    this.callRegistry.add(url);
    return this.axiosInstance.get(url, extra.config);
  }

  public post(
    url: string,
    extra: IRequestNonMandatoryParams = {}
  ): Promise<IResponse> {
    this.callRegistry.add(url);
    return this.axiosInstance.post(url, extra.data, extra.config);
  }

  public put(
    url: string,
    extra: IRequestNonMandatoryParams = {}
  ): Promise<IResponse> {
    this.callRegistry.add(url);
    return this.axiosInstance.put(url, extra.data, extra.config);
  }

  public delete(
    url: string,
    extra: IRequestNonMandatoryParams = {}
  ): Promise<IResponse> {
    this.callRegistry.add(url);
    return this.axiosInstance.delete(url, extra.config);
  }

  // We need to specify Axios because of the interceptors
  private success(response: AxiosResponse) {
    this.callRegistry.delete(response.config.url!);
    return response.data;
  }

  // We need to specify Axios because of the interceptors
  private error(error: AxiosError) {
    this.callRegistry.delete(error.config.url!);

    // if (error.response?.status === 401) {
    // console.log(error.response);
    // console.log(error.config);
    // }

    throw error;
  }
}
