import React from 'react';
import FormContainer from '../../../containers/FormContainer';
import { conceptFormSchema } from '../schema';

export interface ConceptBody {
  id?: string;
  code: string;
  communityId: string;
  description: string;
}

interface ConceptFormProps {
  onSubmit: (body: ConceptBody) => void;
  entity?: ConceptBody;
}

const ConceptForm = ({ onSubmit, entity }: ConceptFormProps) => (
  <FormContainer<ConceptBody>
    schema={conceptFormSchema}
    onSubmit={onSubmit}
    {...(entity ? { data: entity } : {})}
  />
);

export default ConceptForm;
