import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ConceptRoutes from '../../modules/concepts/routes';
import TreasuryRoutes from '../../modules/treasuries/routes';
import MovementRoutes from '../../modules/movements/routes';
import UserRoutes from '../../modules/users/routes';
import CommunityRoutes from '../../modules/communities/routes';
import Dashboard from '../../screens/Dashboard';
import Login from '../../screens/Login';
import MainLayout from '../MainLayout';
import RouteWrapper, { IRoute, IModuleRoutes } from './RouteWrapper';
import { useTranslation } from 'react-i18next';
import Home from '../../screens/Home/Home';
import ForceLogout from '../../screens/ForceLogout/ForceLogout';

export const RoutesByModule: Array<IModuleRoutes> = [
  ConceptRoutes,
  TreasuryRoutes,
  MovementRoutes,
  UserRoutes,
  CommunityRoutes,
];

const UngroupedRoutes: Array<IRoute> = [
  ...ConceptRoutes.routes,
  ...TreasuryRoutes.routes,
  ...MovementRoutes.routes,
  ...UserRoutes.routes,
  ...CommunityRoutes.routes
];

const Routes = () => {
  const { t } = useTranslation();

  return (
    <BrowserRouter>
      <Switch>
        <Route exact={true} key="login" path="/login" component={Login} />
        <Route exact={true} key="force_logout" path="/force-logout" component={ForceLogout} />
        <MainLayout>
          <RouteWrapper
            exact={true}
            key="home"
            path="/"
            component={Home}
            requiredPermissions={[]}
            title={t('modules.home.title')}
          />
          <RouteWrapper
            exact={true}
            key="dashboard"
            path="/dashboard"
            component={Dashboard}
            requiredPermissions={[]}
            title={t('modules.dashboard.title')}
          />
          {UngroupedRoutes.map((routeProps: IRoute) => (
            <RouteWrapper {...routeProps} />
          ))}
        </MainLayout>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
