import { IModuleRoutes, IRoute } from '../../components/Routes/RouteWrapper';
import { Permission } from '../../types/permissions';
import TreasuryDetail from './views/TreasuryDetail';
import TreasuryList from './views/TreasuryList';

export const routePrefix = '/treasuries';

const routes : Array<IRoute> = [{
  exact: true,
  title: 'modules.treasuries.detail',
  navLabel: 'modules.treasuries.list',
  key: 'treasury_list',
  path: `${routePrefix}/list`,
  component: TreasuryList,
  requiredPermissions: [Permission.ADMIN, Permission.ACCOUNTANCY]
}, {
  exact: true,
  title: 'modules.treasuries.detail',
  key: 'treasury_detail',
  path: `${routePrefix}/detail/:Id`,
  component: TreasuryDetail,
  requiredPermissions: [Permission.ADMIN, Permission.ACCOUNTANCY]
}];

const moduleRoutes : IModuleRoutes = {
  key: 'treasuries_module',
  title: 'modules.treasuries.plural',
  requiresCommunity: true,
  routes,
};

export default moduleRoutes;