import React from 'react';
import ListContainer from '../../../containers/ListContainer/ListContainer';
import useApi from '../../../hooks/useAPI';
import { Create, List, Update } from '../../../http/endpoints/concepts';
import { StateRefresh, StateSet } from '../../../state/keys';
import useStore from '../../../state/store';
import { Concept, ConceptSchema } from '../../../types/concept';
import ConceptForm, { ConceptBody } from './ConceptForm';

const ConceptList = () => {
  const { setRefreshConceptsList } = useStore((state) => state);

  const { call: callCreate } = useApi({
    endpoint: Create,
    onSuccess: () => setRefreshConceptsList(true),
  });

  const { call: callUpdate } = useApi({
    endpoint: Update,
    onSuccess: () => setRefreshConceptsList(true),
  });

  const handleCreate = (data: ConceptBody) => callCreate({
    data: {
      ...data,
      code: parseInt(data.code),
    }
  });
  const handleUpdate = (data: ConceptBody) => callUpdate({
    id: data.id,
    data: {
      ...data,
      code: parseInt(data.code),
    }
  });

  return (
    <ListContainer<Concept, ConceptBody>
      entityKey="modules.concepts.single"
      headerTitle="modules.concepts.plural"
      formComponent={ConceptForm}
      handleCreate={handleCreate}
      handleUpdate={handleUpdate}
      getEndpoint={List}
      setRefreshStateKey={StateSet.SET_REFRESH_CONCEPTS_LIST}
      refreshListKey={StateRefresh.REFRESH_CONCEPTS_LIST}
      columns={ConceptSchema}
    />
  );
};

export default ConceptList;
