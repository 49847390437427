import React, { useEffect, useMemo } from 'react';
import { Spin, Table } from 'antd';
import get from 'lodash.get';
import { Entity } from '../../types/entity';
import { ITableColumnSchema } from '../../types/tableSchema';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '../../utils/formatter';
import useStore from '../../state/store';
interface Pagination {
  currentPage: number;
  totalItems: number;
  onChange: (page: number) => void;
  pageSize?: number;
}

export type SummaryItem = {
  index: number,
  colSpan?: number,
  dataKey: string,
  useAsTranslation: boolean,
  formatAsMoney?: boolean
  align?: 'left' | 'right' | 'center';
};

interface DataTableProps<T> {
  loading: boolean;
  columns: Array<ITableColumnSchema>;
  data: Array<T>;
  paginationData?: Pagination;
  summaryData?: {};
  displaySummary?: boolean;
  summarySchema?: Array<SummaryItem>;
}

const DataTable = <T extends Entity>({
  data,
  columns,
  loading = false,
  paginationData,
  summaryData,
  displaySummary,
  summarySchema
}: DataTableProps<T>) => {
  const { t } = useTranslation();
  const {
    exportDataTable,
    setExportDataTable,
    setExportingDataTable,
  } = useStore();
  const translatedColumns = useMemo(
    () =>
      columns.map((columnSchema) => ({
        ...columnSchema,
        title: t(columnSchema.title),
      })),
    [columns, t]
  );

  useEffect(() => {
    if (exportDataTable) {
      setExportingDataTable(true)
      let csv = 'data:text/csv;charset=utf-8,';
      const columsQty = columns.length;
      translatedColumns.forEach((column, i) => {
        if (column.key !== 'action') {
          csv += `${t(column.title!)}`;
          csv += i === (columsQty - 1) ? '\r\n' : ';';
        }
      });
      data.forEach((item) => {
        translatedColumns.forEach((column, i) => {
          if (column.key !== 'action') {            
            const dataToPrint = (item as { [x: string]: any })[column.dataIndex!] as string;
            csv += dataToPrint;
            csv += i === (columsQty - 1) ? '\r\n' : ';';
          }
        });
      });
      const encodedUri = encodeURI(csv);
      window.open(encodedUri);
      setExportingDataTable(false);
      setExportDataTable(false);
    }
  }, [exportDataTable]);

  const summary = useMemo(() => {
    return summarySchema ? (
      <Table.Summary>
        <Table.Summary.Row>
          {summarySchema?.map((col) => {
            const {
              index,
              colSpan,
              dataKey,
              useAsTranslation,
              formatAsMoney,
              align
            } = col;            
            let content = useAsTranslation ? t(dataKey) : get(summaryData, dataKey);
            if (formatAsMoney) content = formatMoney(content);

            return (
              <Table.Summary.Cell
                index={index}
                {...(colSpan ? { colSpan } : {})}
                {...(align ? { align } : {})}
              >
                {content}
              </Table.Summary.Cell>
            );
          })}
        </Table.Summary.Row>
      </Table.Summary>
    ) : null;
  }, [t, summarySchema, summaryData]);

  return (
    <div className="DataTable">
      {loading && <Spin />}
      <Table
        rowKey={'id'}
        size="small"
        dataSource={data}
        columns={translatedColumns}
        {...(displaySummary ? { summary: () => summary } : {})}
        {...(paginationData ? ({
          pagination: {
            current: paginationData.currentPage,
            pageSize: paginationData.pageSize,
            onChange: paginationData.onChange,
            total: paginationData.totalItems,
            showSizeChanger: false,
          }
        }) : { pagination: false })}
      />
    </div>
  );
};

export default DataTable;
