/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import get from 'lodash.get';
import { ITableColumnSchema } from '../../types/tableSchema';
import { Entity } from '../../types/entity';
import useStore from '../../state/store';
import DataTable from '../../components/DataTable';
import { StateRefresh, StateSet } from '../../state/keys';
import IEndpoint from '../../http/interfaces/IEndpoint';
import useApi from '../../hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { PagedList } from '../../types/pagedList';
import { SummaryItem } from '../../components/DataTable/DataTable';

interface DataTableContainerProps {
  endpoint: IEndpoint;
  columns: Array<ITableColumnSchema>;
  setRefreshStateKey: StateSet;
  refreshListKey: StateRefresh;
  fromEntity?: string;
  filters?: {};
  displaySummary?: boolean;
  summarySchema?: Array<SummaryItem>;
  pageSize?: number;
  withoutPagination?: boolean;
}

const DataTableContainer = <T extends Entity>({
  endpoint,
  columns,
  setRefreshStateKey,
  refreshListKey,
  fromEntity,
  filters,
  displaySummary,
  summarySchema,
  pageSize,
  withoutPagination
}: DataTableContainerProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [entityPagedList, setEntityPagedList] = useState<PagedList<T> | null>(null);
  const {
    [setRefreshStateKey]: setRefresh,
    [refreshListKey]: refreshList,
  } =  useStore();
  const [shouldPerformCall, setShouldPerformCall] = useState(true);

  const { call, loading: fetchLoading } = useApi({
    endpoint,
    onSuccess: (rawPagedList: PagedList<T>) => {
      const columnsToFormat = columns.filter((column) => (
        !!column.dataPath || !!column.mapFromObject || !!column.formatFn || !!column.translateIf
      ));
      const pagedList = {...rawPagedList};
      if (columnsToFormat.length) {
        pagedList.list.forEach((item) => {
          columnsToFormat.forEach((column) => {
            if (column.dataPath) {
              (item as { [x: string]: any })[column.key] = get(item, column.dataPath, '');
            } else if (column.mapFromObject) {
              const mappedValue = get(
                column.mapFromObject.objectEnum,
                (item as { [x: string]: any })[column.key],
                ''
              );
              (item as { [x: string]: any })[column.key] = (
                t(`${column.mapFromObject.translationNamespace}.${mappedValue}`)
              );
              (item as { [x: string]: any })[`${column.key}_unformatted`] = mappedValue;
            } else if (column.formatFn) {
              (item as { [x: string]: any })[column.key] = column.formatFn(get(item, column.key, ''));
            } else if (column.translateIf) {
              const valueToTranslate = get(item, column.key, '');
              if (valueToTranslate === column.translateIf.value) {
                (item as { [x: string]: any })[column.key] = t(column.translateIf.translation);
              }
            }
          })
        });
      }
      setEntityPagedList(pagedList);
    },
  });

  const performCall = useCallback(
    () => {
      call({
        ...(fromEntity ? {
          id: fromEntity,
        } : {}),
        config: {
          params: {
            page,
            pageSize: pageSize || 0,
            ...(filters ? filters : {})
          },
        },
      });
      setShouldPerformCall(false);
    },
    [page, fromEntity, filters],
  )

  useEffect(() => {
    performCall();
  }, [shouldPerformCall]);

  useEffect(() => {
    setPage(1);
    setShouldPerformCall(true);
    return () => { setEntityPagedList(null) };
  }, [filters]);

  useEffect(() => {
    performCall();
    return () => { setEntityPagedList(null) };
  }, [page]);

  useEffect(() => {
    if (refreshList) {
      performCall();
      (setRefresh as unknown as (refresh: boolean) => any)(false);
    }
  }, [refreshList]);

  return (
    <DataTable<T>
      data={entityPagedList?.list as unknown as Array<T>}
      summaryData={entityPagedList?.summary}
      loading={fetchLoading}
      columns={columns}
      displaySummary={displaySummary}
      summarySchema={summarySchema}
      {...(
        withoutPagination ? ({
          withoutPagination: true
        }) : ({
          paginationData: {
            currentPage: page,
            onChange: (newPage) => setPage(newPage),
            pageSize,
            totalItems: entityPagedList?.total as unknown as number,
          }
        })
      )}
    />
  );
};

export default DataTableContainer;
