import i18n, { StringMap, TOptions } from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(I18NextHttpBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: '/locales/es.json', // xhr load path for my own fallback
    },
    lng: 'es',
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export const t = (
  key: string | string[],
  options?: string | TOptions<StringMap> | undefined,
): string => i18n.t(key, options);

export default i18n;