import React, { ComponentType, FC } from 'react';
import { Route } from 'react-router';
import useStore from '../../state/store';
import { Permission } from '../../types/permissions';

export interface IBaseRoute {
  exact: boolean;
  key: string;
  path: string;
  component: ComponentType;
}

export interface IRoute extends IBaseRoute {
  title: string;
  navLabel?: string;
  requiredPermissions: Array<Permission>;
  icon?: any;
}

export interface IModuleRoutes {
  key: string;
  title: string;
  requiresCommunity?: boolean;
  routes: Array<IRoute>;
}

const RouteWrapper: FC<IRoute> = ({ component: Component, ...props }) => {
  const { user } = useStore();

  const isAuthorized = (
    props.requiredPermissions?.length === 0 
      || props.requiredPermissions.some((reqPermission) => !!user?.permissions[reqPermission])
  );
  
  if (!isAuthorized) {
    return (<Route {...props} render={() => <>UNAUTHORIZED</>} />);
  }

  return (
    <Route {...props} render={() => <Component {...props} />} />
  );
};

export default RouteWrapper;
