import { useState } from 'react';
import useEphemeralComponent from './useEphemeralComponent';

interface IUseModalProps {
  onDismiss?: () => void;
};

const useModal = ({
  onDismiss
} : IUseModalProps = {}) => {
  const { id, refreshId } = useEphemeralComponent();
  const [visible, setVisible] = useState<boolean>(false);

  const openModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
    refreshId();
    onDismiss?.();
  };

  return {
    id,
    visible,
    openModal,
    closeModal
  };
};

export default useModal;