import React from 'react';
import ListContainer from '../../../containers/ListContainer/ListContainer';
import useApi from '../../../hooks/useAPI';
import { Create, List, SetDefault, Update } from '../../../http/endpoints/treasuries';
import { StateRefresh, StateSet } from '../../../state/keys';
import useStore from '../../../state/store';
import { Treasury, TreasurySchema } from '../../../types/treasury';
import { routePrefix } from '../routes';
import TreasuryForm, { TreasuryBody } from './TreasuryForm';

const TreasuryList = () => {
  const { setRefreshTreasuriesList } = useStore((state) => state);

  const { call: callCreate } = useApi({
    endpoint: Create,
    onSuccess: () => setRefreshTreasuriesList(true),
  });

  const { call: callUpdate } = useApi({
    endpoint: Update,
    onSuccess: () => setRefreshTreasuriesList(true),
  });

  const { call: callSetTreasuryAsDefault } = useApi({
    endpoint: SetDefault,
  });

  const handleCreate = (data: TreasuryBody) => callCreate({ data });
  const handleUpdate = (data: TreasuryBody) => callUpdate({ id: data.id, data });
  
  const selectAndSet = (treasuryId: string) => callSetTreasuryAsDefault({ id: treasuryId, data: {} });

  const isTreasurySelectable = (entity: Treasury) => !entity.default;

  return (
    <ListContainer<Treasury, TreasuryBody>
      entityKey="modules.treasuries.single"
      headerTitle="modules.treasuries.plural"
      formComponent={TreasuryForm}
      handleCreate={handleCreate}
      handleUpdate={handleUpdate}
      getEndpoint={List}
      setRefreshStateKey={StateSet.SET_REFRESH_TREASURIES_LIST}
      refreshListKey={StateRefresh.REFRESH_TREASURIES_LIST}
      selectAndSet={selectAndSet}
      shouldAllowSelect={isTreasurySelectable}
      columns={TreasurySchema}
      allowDetail={routePrefix}
    />
  );
};

export default TreasuryList;
