import React, { useEffect, useState } from 'react';
import ListContainer from '../../../containers/ListContainer/ListContainer';
import useApi from '../../../hooks/useAPI';
import { Create, Delete, List, Update } from '../../../http/endpoints/movements';
import { GetAll as GetAllConcepts } from '../../../http/endpoints/concepts';
import { GetAll as GetAllTreasuries } from '../../../http/endpoints/treasuries';
import { StateRefresh, StateSet } from '../../../state/keys';
import useStore from '../../../state/store';
import { getFormattedSchema, getMovementsSummarySchema, Movement } from '../../../types/movement';
import MovementForm, { MovementBody } from './MovementForm';
import MovementFilters, { IMovementFilter } from '../partials/MovementFilters';

const MovementList = () => {
  const {
    setRefreshMovementsList,
    setConcepts,
    setTreasuries
  } = useStore((state) => state);
  const [filters, setFilters] = useState<IMovementFilter>({
    concepts: [],
    dateFrom: null,
    dateTo: null,
    movementType: 'X',
  });
  
  const { call: getAllConcepts } = useApi({
    endpoint: GetAllConcepts,
    onSuccess: (data) => { setConcepts(data) },
  });

  const { call: getAllTreasuries } = useApi({
    endpoint: GetAllTreasuries,
    onSuccess: (data) => { setTreasuries(data) },
  });

  useEffect(() => {
    getAllConcepts();
    getAllTreasuries();
  }, []);

  const { call: callCreate } = useApi({
    endpoint: Create,
    onSuccess: () => { setRefreshMovementsList(true) },
  });
  
  const { call: callUpdate } = useApi({
    endpoint: Update,
    onSuccess: () => setRefreshMovementsList(true),
  });

  const { call: callDelete } = useApi({
    endpoint: Delete,
    onSuccess: () => setRefreshMovementsList(true),
  });

  const handleCreate = (data: MovementBody) => callCreate({ data });
  const handleUpdate = (data: MovementBody, rawData?: MovementBody) => (
    callUpdate({ id: data.id, data: {
      ...data,
      movementType: data.movementType.length > 1 
        ? rawData!.movementType_unformatted![0].toLocaleUpperCase() : data.movementType,
    } })
  );

  const handleDelete = (data: MovementBody) => callDelete({ id: data.id });

  return (
    <ListContainer<Movement, MovementBody>
      entityKey="modules.movements.single"
      headerTitle="modules.movements.plural"
      formComponent={MovementForm}
      handleCreate={handleCreate}
      handleUpdate={handleUpdate}
      allowDelete
      handleDelete={handleDelete}
      getEndpoint={List}
      setRefreshStateKey={StateSet.SET_REFRESH_MOVEMENTS_LIST}
      refreshListKey={StateRefresh.REFRESH_MOVEMENTS_LIST}
      columns={getFormattedSchema()}
      summarySchema={getMovementsSummarySchema()}
      filters={filters}
      filtersComponent={<MovementFilters onChange={setFilters} />}
      displaySummary
    />
  );
};

export default MovementList;
