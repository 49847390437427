import { useState } from 'react';
import {v4 as uuidv4} from 'uuid';

const useEphemeralComponent = () => {
  const [id, setId] = useState(uuidv4());

  const refreshId = () => {
    setId(uuidv4());
  };

  return {
    id,
    refreshId
  };
};

export default useEphemeralComponent;