import React from 'react';
import DataTableContainer from '../../../containers/DataTableContainer';

import { StateRefresh, StateSet } from '../../../state/keys';
import { getFormattedSchema, getMovementsSummarySchema, Movement, TreasuryMovementsColumns } from '../../../types/movement';
import { GetMovements } from '../../../http/endpoints/treasuries';

const TreasuryMovements = ({
  treasuryId
}: {
  treasuryId: string
}) => (
  <DataTableContainer<Movement>
    endpoint={GetMovements}
    fromEntity={treasuryId}
    setRefreshStateKey={StateSet.SET_REFRESH_MOVEMENTS_LIST}
    refreshListKey={StateRefresh.REFRESH_MOVEMENTS_LIST}
    columns={getFormattedSchema(TreasuryMovementsColumns)}
    summarySchema={getMovementsSummarySchema(1)}
    displaySummary
  />
);

export default TreasuryMovements;
