import React, { useMemo, useState } from 'react'
import { Button, Col, DatePicker, Form, PageHeader, Row, Select } from 'antd';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import './MovementFilters.css';
import { DEFAULT_DATE_API_FORMAT, DEFAULT_DATE_FORMAT } from '../../../constants';
import useStore from '../../../state/store';

export type MovementTypeFilter = 'I' | 'O' | 'X';

export interface IMovementFilter {
  [x: string]: string | string[] | null | MovementTypeFilter;
  concepts: [];
  dateFrom: string | null;
  dateTo: string | null;
  movementType: MovementTypeFilter;
}

interface MovementFiltersProps {
  onChange: (filter: IMovementFilter) => void;
}

const MovementFilters = ({
  onChange
} : MovementFiltersProps) => {
  const { t } = useTranslation();
  const { concepts } = useStore();
  const [formNumber, setFormNumber] = useState<number>(1);
  const [filter, setFilter] = useState<IMovementFilter>({
    concepts: [],
    dateFrom: null,
    dateTo: null,
    movementType: 'X',
  });

  const handleFilterChange = (key: string, value: string | MovementTypeFilter | null) => {
    setFilter((prevFilter) => {
      let preformattedValue : string[] | null = null;
      if (key === 'concepts' && !!value ) {
        const prevConcepts = prevFilter.concepts as string[];
        preformattedValue = prevConcepts ? [...prevConcepts, value] : [value];
      }
      return ({
        ...prevFilter,
        [key]: preformattedValue || value
      });
    });
  };

  const typeOptions = useMemo(() => {
    return [{
      label: t('modules.movements.filters.type.I'),
      value: 'I',
    }, { 
      label: t('modules.movements.filters.type.O'),
      value: 'O',
    }, { 
      label: t('modules.movements.filters.type.X'),
      value: 'X'
    }];
  }, [t]);

  const handleDateFromChange = (value: Moment | null) => {
    if (value) return handleFilterChange('dateFrom', value.format(DEFAULT_DATE_API_FORMAT));
    handleFilterChange('dateFrom', value)
  };
  const handleDateToChange = (value: Moment | null) => {
    if (value) return handleFilterChange('dateTo', value.format(DEFAULT_DATE_API_FORMAT));
    handleFilterChange('dateTo', value)
  };
  const handleMovementTypeChange = (value: MovementTypeFilter) => handleFilterChange('movementType', value);
  
  const handleConceptsChange = (value: string) => handleFilterChange('concepts', value);

  const handleFilterReset = () => {
    setFilter({
      concepts: [],
      dateFrom: null,
      dateTo: null,
      movementType: 'X',
    });
    setFormNumber((prev) => prev + 1);
  };

  return (
    <PageHeader className="pageHeader" >
      <Form
        className="filtersForm"
        layout="inline"
        initialValues={filter}
        key={`filter_form_${formNumber}`}
      >
        <Row className='mainRow'>
          <Col className='mainColumn'>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item name="concepts" label={t('modules.movements.filters.concept')}>
                  <Select
                    onSelect={handleConceptsChange}
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) =>
                      option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {concepts.map((opt) => (
                      <Select.Option key={opt.id} value={opt.id}>
                        {`${opt.code} - ${opt.description}`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="movementType" label={t('modules.movements.filters.type.label')}>
                  <Select onSelect={handleMovementTypeChange}>
                    {typeOptions.map((opt) => (
                      <Select.Option key={opt.value} value={opt.value}>{opt.label}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="dateFromFilter" label={t('modules.movements.filters.dateFrom')}>
                  <DatePicker
                    format={DEFAULT_DATE_FORMAT}
                    onChange={handleDateFromChange}
                    placeholder={t('common.selectDate')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="dateToFilter" label={t('modules.movements.filters.dateTo')}>
                  <DatePicker
                    format={DEFAULT_DATE_FORMAT}
                    onChange={handleDateToChange}
                    placeholder={t('common.selectDate')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className='mainColumn filterButtons'>
            <Button onClick={handleFilterReset}>{t('common.filters.reset')}</Button>
            <Button onClick={() => onChange(filter)} type='primary'>{t('common.filters.apply')}</Button>
          </Col>
        </Row>
      </Form>
    </PageHeader>
  );
};

export default MovementFilters;