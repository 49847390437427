import IEndpoint, { HttpMethods } from '../../interfaces/IEndpoint';

const ListConceptsEndpoint: IEndpoint = {
    method: HttpMethods.GET,
    url: 'concepts/list',
    includeCommunityId: true,
    formatError: (errorResponse) => errorResponse.response.data,
};

export default ListConceptsEndpoint;
