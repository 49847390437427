export enum HttpMethods {
	GET = 'get',
	POST = 'post',
	PUT = 'put',
	DELETE = 'delete',
}

interface IEndpoint {
	method: HttpMethods;
	url: string;
	composeUrl?: (id: string) => string;
	includeCommunityId?: boolean;
	formatResponse?: (responseData: any) => any;
	formatError?: (errorResponse: any) => any;
}

export default IEndpoint;
