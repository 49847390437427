import React, { FC, useEffect, useRef } from 'react';
import { Layout, Spin } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';

import './MainLayout.css';
import Sidebar from '../Sidebar';
import useApi from '../../hooks/useAPI';
import { GetUserPermissions } from '../../http/endpoints/authorization';
import useStore from '../../state/store';
import AuthenticationService from '../../services/Authentication';
import { useHistory } from 'react-router-dom';

interface MainLayoutProps {}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const authService = useRef<AuthenticationService>(
    AuthenticationService.getInstance()
  );
  const history = useHistory();
  const { permissions, setUser, user } = useStore();

  const { call: fetchUserPermissions } = useApi({
    endpoint: GetUserPermissions,
    onSuccess: (data) => setUser(data)
  });

  useEffect(() => {
    if (!user && !authService.current.isAuthenticated()) history.push('/login');
  }, [user]);

  useEffect(() => {
    if (authService.current.isAuthenticated() && !user) fetchUserPermissions();
  }, []);

  if (!permissions || !user) return <Spin />;

  return (
    <Layout className="MainLayout">
      <Sidebar />
      <Layout className="site-layout" style={{ marginLeft: 200 }}>
        <Content className="content">
          {children}
        </Content>
        <Footer className="footer">{`©${new Date().getFullYear()} - Juan Emanuel Di Modugno`}</Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
