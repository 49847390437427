import React, { Suspense, useEffect } from 'react';
import useApi from '../../hooks/useAPI';
import { GetAll } from '../../http/endpoints/authorization';
import useStore from '../../state/store';
import Routes from '../Routes/Routes';
import './App.css';

const App = () => {
  const { setPermissions, refreshPermissions, setRefreshPermissions } = useStore();
  const { call: fetchPermissions } = useApi({
    endpoint: GetAll,
    onSuccess: (data) => {
      setPermissions(data)
      setRefreshPermissions(false);
    }
  });

  useEffect(() => {
    fetchPermissions();
  }, []);

  useEffect(() => {
    if (refreshPermissions) fetchPermissions();
  }, [refreshPermissions]);

  return (
    <Suspense fallback={<span>Loading…</span>}>
      <div className="App">
        <Routes />
      </div>
    </Suspense>
  );
};

export default App;
