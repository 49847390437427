import { ITableColumnSchema } from './tableSchema';

export type Concept = {
  id: string;
  description: string;
  code?: string;
  createdAt: Date;
};

export const ConceptSchema: Array<ITableColumnSchema> = [
  {
    title: 'modules.concepts.schema.code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'modules.concepts.schema.description',
    dataIndex: 'description',
    key: 'description',
  },
];
