import { ITableColumnSchema } from './tableSchema';

export type User = {
  id: string;
  username: string;
  communityId: string;
  permissions: {
    [x: string]: boolean;
  }
};

export const UserSchema: Array<ITableColumnSchema> = [
  {
    title: 'modules.users.schema.username',
    dataIndex: 'username',
    key: 'username',
  },
];
