import { IModuleRoutes, IRoute } from '../../components/Routes/RouteWrapper';
import { Permission } from '../../types/permissions';
import CommunityList from './views/CommunityList';

const routePrefix = '/communities';

const routes : Array<IRoute> = [{
  exact: true,
  title: 'modules.communities.list',
  navLabel: 'modules.communities.list',
  key: 'community_list',
  path: `${routePrefix}/list`,
  component: CommunityList,
  requiredPermissions: [Permission.ADMIN]
}];

const moduleRoutes : IModuleRoutes = {
  key: 'communities_module',
  title: 'modules.communities.plural',
  routes,
};

export default moduleRoutes;