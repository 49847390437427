import { Movement } from './movement';
import { ITableColumnSchema } from './tableSchema';

export type Treasury = {
  id: string;
  description: string;
  cashDate: Date;
  initialBalance: number;
  createdAt: Date;
  balance: number;
  cashMovement?: Movement[];
  default: boolean;
};

export const TreasurySchema: Array<ITableColumnSchema> = [
  {
    title: 'modules.treasuries.schema.description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'modules.treasuries.schema.cashDate',
    dataIndex: 'cashDate',
    key: 'cashDate',
  },
];
