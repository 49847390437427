import { FieldType, IFormSchema } from '../../types/schema';

enum ConceptKeys {
  FORM_NAME = 'concept_form',
  DESCRIPTION = 'description',
  CODE = 'code'
}

export const conceptFormSchema : IFormSchema = {
  name: ConceptKeys.FORM_NAME,
  initialValues: {
    remember: false,
  },
  submit: {
    label: 'common.confirm',
    className: 'concept-form-button'
  },
  fields: [{
    name: ConceptKeys.DESCRIPTION,
    label: 'modules.concepts.form.fields.description',
    type: FieldType.TEXT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
  }, {
    name: ConceptKeys.CODE,
    label: 'modules.concepts.form.fields.code',
    type: FieldType.TEXT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
  }]
}