import IEndpoint, { HttpMethods } from '../../interfaces/IEndpoint';

const GetConceptsEndpoint: IEndpoint = {
    method: HttpMethods.GET,
    url: 'concepts',
    includeCommunityId: true,
    formatError: (errorResponse) => errorResponse.response.data,
};

export default GetConceptsEndpoint;
