import React, { useEffect, useRef, useState } from 'react';
import { Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { useTranslation } from 'react-i18next';
import './Sidebar.css';
import { RoutesByModule } from '../Routes/Routes';
import { Link, useHistory } from 'react-router-dom';
import SessionService from '../../services/Session';
import useStore from '../../state/store';
import AuthenticationService from '../../services/Authentication';
import { isNull } from '../../utils/misc';
import useApi from '../../hooks/useAPI';
import { Find } from '../../http/endpoints/communities';
import { Community } from '../../types/community';

const Sidebar = () => {
  const authService = useRef<AuthenticationService>(
    AuthenticationService.getInstance()
  );
  const [community, setCommunity] = useState<Community|null>(null);
  const { t } = useTranslation();
  const { currentCommunity, setCurrentCommunity, setCleanSession, user } = useStore();
  const history = useHistory();

  const { call } = useApi({
    endpoint: Find,
    onSuccess: (data) => {
      setCommunity(data);
    },
  });

  useEffect(() => {
    if (!isNull(authService.current.getCommunityId())) {
      setCurrentCommunity(authService.current.getCommunityId());
    }
  }, [authService]);

  useEffect(() => {
    if (currentCommunity) call({ id: currentCommunity });
  }, [currentCommunity]);

  const allowedRoutes = RoutesByModule.filter((routesGroup) => {
    if (routesGroup.requiresCommunity && !currentCommunity) return false;
    let shouldRenderGroup = false;
    const nestedRoutes = routesGroup.routes.filter((route) => {      
      return (
        route.requiredPermissions?.length === 0 
          || route.requiredPermissions.some((reqPermission) => !!user?.permissions[reqPermission]
      ));
    });
    if (nestedRoutes.length) shouldRenderGroup = true;
    return shouldRenderGroup;
  });

  const signout = () => {
    SessionService.destroyCurrentSession(() => {
      setCleanSession();
    });
  };

  const handleHome = () => {
    history.push('/');
  };

  return (
    <Sider className="sider">
      <div className="logo">
        <img
          onClick={handleHome}
          alt="logo"
          src={`${process.env.PUBLIC_URL}/logotipo.png`}
        />
      </div>
      <div className="menuesContainer">
        <Menu className="modulesMenu" theme="dark" mode="inline">
          {
            !!community && (
              <>
                <Menu.ItemGroup
                  key="community_name"
                  title={community.name}
                />
                <Menu.Divider />
              </>
            )
          }
          {
            allowedRoutes.map(({ key, title, routes }) => (
              <Menu.ItemGroup key={key} title={t(title)}>
                {routes.filter((route) => route.navLabel).map((route) => (
                  <Menu.Item
                    key={route.key}
                    title={t(`${route.navLabel}`)}
                    {...(route.icon ? { icon: route.icon } : {})}
                  >
                    <Link to={route.path}>{t(`${route.navLabel}`)}</Link>
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            ))
          }
        </Menu>
        <Menu className="sessionMenu" theme="dark" mode="inline">
          <Menu.Divider />
          <Menu.Item
            key="logout_button"
            title={t('common.logout')}
            onClick={signout}
          >
            {t('common.logout')}
          </Menu.Item>
        </Menu>
      </div>
    </Sider>
  );
};

export default Sidebar;
