import { IModuleRoutes, IRoute } from '../../components/Routes/RouteWrapper';
import { Permission } from '../../types/permissions';
import ConceptList from './views/ConceptList';
import ConceptTotals from './views/ConceptTotals';

const routePrefix = '/concepts';

const routes : Array<IRoute> = [{
  exact: true,
  title: 'modules.concepts.list',
  navLabel: 'modules.concepts.list',
  key: 'concept_list',
  path: `${routePrefix}/list`,
  component: ConceptList,
  requiredPermissions: [Permission.ADMIN, Permission.ACCOUNTANCY]
}, {
  exact: true,
  title: 'modules.concepts.totals',
  navLabel: 'modules.concepts.totals',
  key: 'concept_totals',
  path: `${routePrefix}/totals`,
  component: ConceptTotals,
  requiredPermissions: [Permission.ADMIN, Permission.ACCOUNTANCY]
}];

const moduleRoutes : IModuleRoutes = {
  key: 'concepts_module',
  title: 'modules.concepts.plural',
  requiresCommunity: true,
  routes,
};

export default moduleRoutes;