import React from 'react';
import FormContainer from '../../../containers/FormContainer';
import useApi from '../../../hooks/useAPI';
import { CreateRole } from '../../../http/endpoints/authorization';
import { roleCreationFormSchema } from '../schema';

export interface RoleCreationBody {
  description: string,
}

const RoleCreationModal = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void,
  onError: (error: string) => void,
}) => {
  const { call, loading } = useApi({
    endpoint: CreateRole,
    onSuccess,
    onError
  });

  const handleSubmit = (data: RoleCreationBody) => {
    call({data});
  };

  return (
    <FormContainer
      processing={loading}
      onSubmit={handleSubmit} 
      schema={roleCreationFormSchema}      
    />
  );
};

export default RoleCreationModal;
