import React, { useEffect, useState } from 'react';
import { Card, Col, PageHeader, Row, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';
import useApi from '../../hooks/useAPI';
import { GetAllConsolidated } from '../../http/endpoints/treasuries';
import { Treasury } from '../../types/treasury';
import { formatMoney, padDecimals } from '../../utils/formatter';
import { useHistory } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [treasuries, setTreasuries] = useState<Treasury[]>();

  const { call: getAllTreasuries, loading } = useApi({
    endpoint: GetAllConsolidated,
    onSuccess: (data) => { setTreasuries(data) },
  });

  useEffect(() => {
    getAllTreasuries();
  }, []);

  if (loading && !treasuries) return <Spin />;

  const openTreasuryDetail = (id: string) => history.push(`treasuries/detail/${id}`);

  return (
    <>
      <PageHeader
        title={t('modules.dashboard.treasuryStatus')}
        style={{ marginBottom: 16, backgroundColor: 'white' }}
      />
      <Row
        gutter={[8, 8]}
      >
        {treasuries?.map((treasury) => (
          <Col
            className="treasuryCard"
            span={12}
            key={treasury.id}
            onClick={() => openTreasuryDetail(treasury.id)}
          >
            <Card title={treasury.description} hoverable>
              <Text strong>{t('modules.treasuries.schema.balance')}:&nbsp;</Text>
              <Text>{formatMoney(padDecimals(treasury.balance))}</Text>
              <br />
              <Text strong>{t('modules.treasuries.schema.lastCashDate')}:&nbsp;</Text>
              <Text>{treasury.cashDate}</Text>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Dashboard;
