import React, { useEffect, useMemo, useState } from 'react';
import { Button, Descriptions, Modal, PageHeader, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { User } from '../../../types/user';
import { Find } from '../../../http/endpoints/users';
import useApi from '../../../hooks/useAPI';
import UpdatePasswordModal from '../partials/UpdatePasswordModal';
import RoleAssignmentModal from '../partials/RoleAssignmentModal';
import RoleCreationModal from '../partials/RoleCreationModal';
import useStore from '../../../state/store';
import useModal from '../../../hooks/useModal';

const UserDetail = () => {
  const history = useHistory();
  const { Id } = useParams<{Id: string}>();
  const { t } = useTranslation();
  const { setRefreshPermissions, refreshUserDetail, setRefreshUserDetail } = useStore();
  const {
    id: roleCreationModalId,
    visible: roleCreationModalVisible,
    openModal: openRoleCreationModal,
    closeModal: closeRoleCreationModal
  } = useModal({
    onDismiss: () => setRefreshPermissions(true)
  });
  const {
    id: roleAssignmentModalId,
    visible: roleAssignmentModalVisible,
    openModal: openRoleAssignmentModal,
    closeModal: closeRoleAssignmentModal
  } = useModal({
    onDismiss: () => setRefreshUserDetail(true),
  });
  const {
    id: updatePasswordModalId,
    visible: updatePasswordModalVisible,
    openModal: openUpdatePasswordModal,
    closeModal: closeUpdatePasswordModal
  } = useModal();
  const [user, setUser] = useState<User|null>(null);

  const { call, loading } = useApi({
    endpoint: Find,
    onSuccess: (data) => {
      setUser(data);
      setRefreshUserDetail(false);
    },
  });
  
  useEffect(() => {
    if (Id) call({ id: Id });
    return () => { setUser(null) };
  }, [Id]);

  useEffect(() => {
    if (Id && refreshUserDetail) call({ id: Id });
  }, [Id, refreshUserDetail]);

  const userPermissionsAsList : string[] = useMemo(() => {
    return user ? Object.keys(user?.permissions) : [];
  }, [user]);

  const refreshDetail = (callback: () => void) => {
    callback();
  };

  if (loading) return <Skeleton active />;

  return user && (
    <>
      <Modal
        key={updatePasswordModalId}
        title={t('modules.users.updatePassword')}
        visible={updatePasswordModalVisible}
        onCancel={closeUpdatePasswordModal}
        footer={null}
      >
        <UpdatePasswordModal
          userId={Id}
          onSuccess={() => refreshDetail(closeUpdatePasswordModal)}
          onError={(error) => console.log(error)}
        />
      </Modal>
      <Modal
        key={roleAssignmentModalId}
        title={t('modules.users.assignRole')}
        visible={roleAssignmentModalVisible}
        onCancel={closeRoleAssignmentModal}
        footer={null}
      >
        <RoleAssignmentModal
          userId={Id}
          onSuccess={() => refreshDetail(closeRoleAssignmentModal)}
          onError={(error) => console.log(error)}
        />
      </Modal>
      <Modal
        key={roleCreationModalId}
        title={t('modules.roles.createRole')}
        visible={roleCreationModalVisible}
        onCancel={closeRoleCreationModal}
        footer={null}
      >
        <RoleCreationModal
          onSuccess={() => refreshDetail(closeRoleCreationModal)}
          onError={(error) => console.log(error)}
        />
      </Modal>
      <PageHeader
        onBack={() => history.goBack()}
        style={{ marginBottom: 16, backgroundColor: 'white' }}
        title={`${t('modules.users.single')}: ${user?.username}`}
        extra={[
          <Button
            key="role_creation"
            onClick={openRoleCreationModal}
          >
            {t('modules.roles.createRole')}
          </Button>,
          <Button
            key="role_assignment"
            onClick={openRoleAssignmentModal}
          >
            {t('modules.users.assignRole')}
          </Button>,
          <Button
            key="update_password"
            onClick={openUpdatePasswordModal}
          >
            {t('modules.users.updatePassword')}
          </Button>,
        ]}
      >
        <Descriptions size="small" bordered >
          <Descriptions.Item label={t('modules.users.schema.id')} span={3}>
            {user.id}
          </Descriptions.Item>
          <Descriptions.Item label={t('modules.users.schema.permissions')} span={3}>
          {
            userPermissionsAsList.map((permission) => (
              <>
                {t(`modules.users.permissions.${permission}`)}
                <br />
              </>
            ))
          }
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
    </>
  );
};

export default UserDetail;