import IEndpoint, { HttpMethods } from '../../interfaces/IEndpoint';

const SetTreasuryAsDefaultEndpoint: IEndpoint = {
    method: HttpMethods.PUT,
    url: 'treasuries',
    composeUrl: (treasuryId: string) => `treasuries/${treasuryId}/make-default`,
    formatError: (errorResponse) => errorResponse.response.data,
};

export default SetTreasuryAsDefaultEndpoint;
