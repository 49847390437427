import SessionFields from '../enums/Session';

type Session = {
  [x: string]: string;
};

const saveSessionEvent = new CustomEvent('sessionSaved');
const destroySessionEvent = new CustomEvent('sessionDestroyed');

class SessionService {
  public static saveCurrentSession = (session: Session): Promise<any> => {
    return new Promise(() => {
      Object.keys(session).forEach((key) => {
        localStorage.setItem(`session_${key}`, session[key]);
      });
      dispatchEvent(saveSessionEvent);
    });
  };

  public static destroyCurrentSession = (cb: Function) => {
    try {
      Object.keys(SessionFields).forEach((key) => {
        localStorage.removeItem(`session_${key}`);
      });
      dispatchEvent(destroySessionEvent);
      cb();
    } catch (error) {
      console.log('Error during session persistance');
    }
  };

  public static getCurrentSession = (): Session => {
    const currentSession: Session = {};
    Object.values(SessionFields).forEach((key: string) => {
      currentSession[key] = localStorage.getItem(`session_${key}`) || '';
    });
    return currentSession;
  };
}

export default SessionService;
