import React from 'react';
import FormContainer from '../../../containers/FormContainer';
import { userFormSchema } from '../schema';

export interface UserBody {
  id?: string;
  communityId: string;
  password: string;
}

interface UserFormProps {
  onSubmit: (body: UserBody) => void;
  entity?: UserBody;
}

const UserForm = ({ onSubmit, entity }: UserFormProps) => (
  <FormContainer<UserBody>
    schema={userFormSchema}
    onSubmit={onSubmit}
    {...(entity ? { data: entity } : {})}
  />
);

export default UserForm;
