import React, { useRef } from 'react';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import LoginForm, { LoginBody } from '../../components/LoginForm';

import './Login.css';
import useApi from '../../hooks/useAPI';
import { LoginEndpoint } from '../../http/endpoints/auth';
import AuthenticationService from '../../services/Authentication';
import SessionService from '../../services/Session';
import { Redirect } from 'react-router-dom';
import { GetUserPermissions } from '../../http/endpoints/authorization';
import useStore from '../../state/store';

const Login = () => {
  const authService = useRef<AuthenticationService>(
    AuthenticationService.getInstance()
  );
  const { setUser } = useStore();

  const { call: fetchUserPermissions } = useApi({
    endpoint: GetUserPermissions,
    onSuccess: (data) => setUser(data),
  });

  const { call: callLogin } = useApi({
    endpoint: LoginEndpoint,
    onSuccess: (session) => {
      fetchUserPermissions();
      SessionService.saveCurrentSession(session);
    },
  });

  const handleSubmit = (data: LoginBody) => {
    const { username, password } = data;
    callLogin({
      data: {
        username,
        password: AuthenticationService.encryptPassword(password),
      },
    });
  };

  if (authService.current.isAuthenticated()) return <Redirect to="/" exact />;

  return (
    <Layout className="Login">
      <Content>
        <div className="contentWrapper">
          <LoginForm onSubmit={handleSubmit} />
        </div>
      </Content>
    </Layout>
  );
};

export default Login;
