import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useStore from '../../state/store';
import { Permission } from '../../types/permissions';

const Home = () => {
  const { user } = useStore();
  const history = useHistory();

  useEffect(() => {
    if (user?.permissions[Permission.ADMIN]) {
      history.push('/communities/list');
    } else if (user?.permissions[Permission.ACCOUNTANCY]) {
      history.push('/dashboard');
    }
  }, [user]);

  return (
    <div>Home</div>
  )
};

export default Home;