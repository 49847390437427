import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import useApi from '../../../hooks/useAPI';
import { Totals } from '../../../http/endpoints/concepts';
import { SummarizedEntity } from '../../../types/summarizedEntity';
import { formatMoney } from '../../../utils/formatter';
import ConceptMovementsFilters, { IConceptMovementFilter } from '../partials/ConceptMovementsFilters';

const ConceptTotals = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<IConceptMovementFilter>({
    dateFrom: null,
    dateTo: null,
    includeZeroValues: false,
  });

  const [totals, setTotals] = useState<SummarizedEntity[]>([]);
  
  const { call, loading } = useApi({
    endpoint: Totals,
    onSuccess: (data: SummarizedEntity[]) => {
      setTotals(data.map(({ description, total }) => ({
        description,
        total: formatMoney(total)
      })))
    },
  });

  useEffect(() => {
    if (filters.dateFrom && filters.dateTo) {
      call({
        config: {
          params: {
            ...(filters ? filters : {})
          }
        }
      });
    }
  }, [filters]);

  const columns = [
    {
      title: t('modules.concepts.single'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('common.total'),
      dataIndex: 'total',
      key: 'total',
      align: 'right'
    },
  ]
  
  return (
    <div>
      <ConceptMovementsFilters onChange={setFilters} />
      <Table
        loading={loading}
        rowKey={'description'}
        columns={columns as ColumnType<any>[]}
        size="small"
        dataSource={totals as SummarizedEntity[]}
        pagination={false}
      />
    </div>
  );
};

export default ConceptTotals;