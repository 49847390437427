import React from 'react';
import ListContainer from '../../../containers/ListContainer/ListContainer';
import useApi from '../../../hooks/useAPI';
import { Create, List, Update } from '../../../http/endpoints/communities';
import { StateRefresh, StateSet } from '../../../state/keys';
import useStore from '../../../state/store';
import { Community, CommunitySchema } from '../../../types/community';
import CommunityForm, { CommunityBody } from './CommunityForm';

const CommunityList = () => {
  const { setRefreshCommunitiesList, setCurrentCommunity } = useStore((state) => state);

  const { call: callCreate } = useApi({
    endpoint: Create,
    onSuccess: () => setRefreshCommunitiesList(true),
  });

  const { call: callUpdate } = useApi({
    endpoint: Update,
    onSuccess: () => setRefreshCommunitiesList(true),
  });

  const handleCreate = (data: CommunityBody) => callCreate({ data });
  const handleUpdate = (data: CommunityBody) => callUpdate({ id: data.id, data });

  const selectAndSet = (communityId: string) => {
    localStorage.setItem('session_community_id', communityId);
    setCurrentCommunity(communityId);
  };

  return (
    <ListContainer<Community, CommunityBody>
      entityKey="modules.communities.single"
      headerTitle="modules.communities.plural"
      formComponent={CommunityForm}
      handleCreate={handleCreate}
      handleUpdate={handleUpdate}
      getEndpoint={List}
      setRefreshStateKey={StateSet.SET_REFRESH_COMMUNITIES_LIST}
      refreshListKey={StateRefresh.REFRESH_COMMUNITIES_LIST}
      columns={CommunitySchema}
      selectAndSet={selectAndSet}
      allowSelect
    />
  );
};

export default CommunityList;
