import { Moment } from 'moment';
import { StateKey } from '../state/keys';
import { Permission } from './permissions';

export enum FieldType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  SELECT = 'select',
}

interface IRule {
  required: boolean;
  message: string;
}

export interface IField {
  name: string;
  label?: string;
  rules: Array<IRule>;
  type: FieldType;
  placeholder?: string;
  disabled?: boolean,
  extra?: {
    autocomplete?: boolean;
    min?: number,
    max?: number,
    decimalSeparator?: string,
    optionsFrom?: StateKey,
    shouldTranslate?: string,
    excludeOption?: string,
    staticOptions?: { id: string, description: string }[],
    avoidDuplicatedValueFrom?: string,
    validateMaxFrom?: string,
    prefix?: any,
    type?: string,
    allowSearch?: boolean,
    disabledDate?: ((current: Moment) => boolean),
    avoidDateRestrictions?: Permission[];
    dateRestrictions?: {
      between: {
        from: Moment,
        to: Moment,
      }
    }
  };
}

export interface IFormSchema {
  name: string;
  initialValues: {
    [x: string]: any;
  };
  submit: {
    label: string;
    className: string;
  };
  fields: Array<IField>;
}
