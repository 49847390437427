import React from 'react';
import { Moment } from 'moment';
import FormContainer from '../../../containers/FormContainer';
import { initializeDate } from '../../../utils/fieldHelper';
import { treasuryFormSchema } from '../schema';

export interface TreasuryBody {
  id?: string;
  communityId: string;
  description: string;
  cashDate: Moment;
  initialBalance: number;
}

interface TreasuryFormProps {
  onSubmit: (body: TreasuryBody) => void;
  entity?: TreasuryBody;
}

const TreasuryForm = ({ onSubmit, entity }: TreasuryFormProps) => (
  <FormContainer<TreasuryBody>
    schema={treasuryFormSchema}
    onSubmit={onSubmit}
    {...(entity ? { data: {
      ...entity,
      cashDate: initializeDate(entity.cashDate)
    } } : {})}
  />
);

export default TreasuryForm;
