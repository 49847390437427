import { StateKey } from '../../state/keys';
import { Permission } from '../../types/permissions';
import { FieldType, IFormSchema } from '../../types/schema';

enum UserKeys {
  FORM_NAME = 'user_form',
  UPDATE_PASSWORD_FORM_NAME = 'update_password_form',
  ROLE_ASSIGNMENT_FORM_NAME = 'role_assignment_form',
  ROLE_CREATION_FORM_NAME = 'role_creation_form',
  USERNAME = 'username',
  DESCRIPTION = 'description',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'passwordConfirmation',
  ROLE_ID = 'roleId',
}

export const userFormSchema : IFormSchema = {
  name: UserKeys.FORM_NAME,
  initialValues: {
    remember: false,
  },
  submit: {
    label: 'common.confirm',
    className: 'concept-form-button'
  },
  fields: [{
    name: UserKeys.USERNAME,
    label: 'modules.users.form.fields.username',
    type: FieldType.TEXT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
  }, {
    name: UserKeys.PASSWORD,
    label: 'modules.users.form.fields.password',
    type: FieldType.TEXT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
    extra: {
      type: 'password',
    }
  }]
};

export const updatePasswordSchema : IFormSchema = {
  name: UserKeys.UPDATE_PASSWORD_FORM_NAME,
  initialValues: {
    remember: false,
  },
  submit: {
    label: 'common.confirm',
    className: 'update-password-form-button'
  },
  fields: [{
    name: UserKeys.PASSWORD,
    label: 'modules.users.form.fields.password',
    type: FieldType.TEXT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
    extra: {
      type: 'password',
    }
  }, {
    name: UserKeys.CONFIRM_PASSWORD,
    label: 'modules.users.form.fields.confirmPassword',
    type: FieldType.TEXT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
    extra: {
      type: 'password',
    }
  }]
};

export const roleAssignmentFormSchema : IFormSchema = {
  name: UserKeys.ROLE_CREATION_FORM_NAME,
  initialValues: {
    remember: false,
  },
  submit: {
    label: 'common.confirm',
    className: 'role-creation-form-button'
  },
  fields: [{
    name: UserKeys.ROLE_ID,
    label: 'modules.users.form.fields.role',
    type: FieldType.SELECT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }],
    extra: {
      optionsFrom: StateKey.PERMISSIONS,
      shouldTranslate: 'modules.users.permissions',
      excludeOption: Permission.ADMIN,
    }
  }]
}

export const roleCreationFormSchema : IFormSchema = {
  name: UserKeys.ROLE_ASSIGNMENT_FORM_NAME,
  initialValues: {
    remember: false,
  },
  submit: {
    label: 'common.confirm',
    className: 'role-creation-form-button'
  },
  fields: [{
    name: UserKeys.DESCRIPTION,
    label: 'modules.roles.form.fields.description',
    type: FieldType.TEXT,
    rules: [{
      required: true,
      message: 'common.requiredField',
    }]
  }]
}