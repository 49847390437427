import IEndpoint, { HttpMethods } from '../../interfaces/IEndpoint';

const CreateConceptEndpoint: IEndpoint = {
	method: HttpMethods.POST,
	includeCommunityId: true,
	url: 'concepts',
	formatError: (errorResponse) => errorResponse.response.data,
};

export default CreateConceptEndpoint;
