import React from 'react';
import FormContainer from '../../../containers/FormContainer';
import useApi from '../../../hooks/useAPI';
import { AssignRole } from '../../../http/endpoints/authorization';
import { roleAssignmentFormSchema } from '../schema';

export interface RoleAssignmentBody {
  userId: string,
  roleId: string,
}

const RoleAssignmentModal = ({
  userId,
  onSuccess,
  onError,
}: {
  userId: string,
  onSuccess: () => void,
  onError: (error: string) => void,
}) => {
  const { call, loading } = useApi({
    endpoint: AssignRole,
    onSuccess,
    onError
  });

  const handleSubmit = (data: RoleAssignmentBody) => {
    call({
      data: {
        userId,
        roleId: data.roleId
      },
    });
  };

  return (
    <FormContainer
      processing={loading}
      onSubmit={handleSubmit} 
      schema={roleAssignmentFormSchema}      
    />
  );
};

export default RoleAssignmentModal;
